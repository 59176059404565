import React, { useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles, lighten } from '@material-ui/core/styles';

const ProgressBar = (props) => {
  const { startBarProgress } = props;
  const { startBarProgressRUT } = props;

  const [progress, setProgress] = React.useState(0);

  const BorderLinearProgress = withStyles({
    root: {
      height: 6,
      width: 233,
      backgroundColor: lighten('#FFFFFF', 0.5),
      border: '1px solid #C1C1C1',
      opacity: 1,
    },
    bar: {
      backgroundColor: '#009BDD',
    },
  })(LinearProgress);

  useEffect(() => {
    if (startBarProgress) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            return 0;
          }
          const diff = Math.random() * 50;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);
    }
    if (startBarProgressRUT) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            return 0;
          }
          const diff = Math.random() * 50;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);
    }
  }, [startBarProgress, startBarProgressRUT]);

  return (
    <div style={{ display: progress === 100 || progress === 0 ? 'none' : '' }}>
      <BorderLinearProgress variant="determinate" value={progress} />
    </div>
  );
};

export default ProgressBar;
