import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import { clearMessageAlertAction } from '../../actions/general.action';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.statusLicense.containerMessage.backgroundColor,
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  dialogAlert: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
    },
  },
  titleAlert: {
    fontFamily: 'Muli, Arial',
    color: theme.alertPopup.titleAlert.color,
    fontWeight: 700,
    fontSize: 20,
    paddingTop: 15,
  },
  closeButton: {
    ...theme.alertPopup.iconClose,
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
  },
  promoForm: {
    flexDirection: 'row',
    border: '1px solid #F1F1F1',
    padding: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  ButtonCancel: {
    marginLeft: 15,
    marginRight: 15,
    ...theme.alertPopup.buttonCancel,
    fontSize: 14,
    minWidth: 150,
    borderRadius: 10,
  },
  ButtonAccept: {
    ...theme.alertPopup.buttonAccept,
    fontSize: 14,
    minWidth: 150,
    borderRadius: 10,
  },
  subMensaje: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 700,
  },
  buttonsAlert: {
    marginTop: 25,
    marginBottom: 15,
  },
}));

const AlertConfirm = (props) => {
  const {
    isOpen,
    onClose,
    showClose = true,
    tituloModal,
    mensajeModal,
    subMensaje,
    textButtonCancel,
    textButtonAcept,
    onCancel,
    handleAccept,
    isOnlyMessage,
    textAlignMessage = 'center',
    setOpenAlertInfo,
    clearMessageAlert,
    messageAlert,
  } = props;

  const classes = useStyles();

  React.useEffect(() => {
    if (messageAlert?.autoHideAlert === true) {
      setTimeout(() => {
        setOpenAlertInfo(false);
        clearMessageAlert();
      }, 7000);
    }
  });

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        className={classes.dialogAlert}
      >
        <DialogTitle className={classes.root}>
          <Typography align="center" className={classes.titleAlert} style={messageAlert?.styleTitle}>{tituloModal}</Typography>
          {
            showClose && (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )
          }
        </DialogTitle>
        <DialogContent className={`${classes.root} ${classes.dialogContent}`} style={messageAlert?.popupStyle}>
          <DialogContentText style={{ textAlign: textAlignMessage }} component="span">
            <Grid container spacing={2} style={messageAlert?.contentStyle}>
              {
                messageAlert?.img && (
                  <>
                    <img src={messageAlert?.img} alt="img-popup" style={messageAlert?.imgStyle} />
                    <br />
                  </>
                )
              }
              <Grid item xs={12} style={messageAlert?.styleMessage}>
                {mensajeModal}
              </Grid>
              {
                subMensaje && (
                  <Grid item xs={12} className={classes.subMensaje}>
                    {subMensaje}
                  </Grid>
                )
              }
            </Grid>
          </DialogContentText>
          {props.children}
          {!isOnlyMessage
            && (
              <Grid container justifyContent="center" className={classes.buttonsAlert}>
                {onCancel
                  && (
                    <Button
                      variant="contained"
                      onClick={onClose}
                      className={`${classes.ButtonCancel}`}
                    >
                      {textButtonCancel || 'Cancelar'}
                    </Button>
                  )}
                <Button
                  variant="contained"
                  onClick={handleAccept}
                  style={{ minWidth: '40%' }}
                  className={`${classes.ButtonAccept}`}
                >
                  {textButtonAcept || 'Aceptar'}
                </Button>
              </Grid>
            )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
  clearMessageAlert: clearMessageAlertAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertConfirm);
