import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ckeckimage from '../../../assets/img/iconos/ibuhoo/ckeckimage.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 12,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '35px',
    boxShadow: '2px 2px 2px -2px rgba(75,75,75,0.34)',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    boxShadow: '2px 2px 2px -2px rgba(75,75,75,0.34)',
    borderRadius: 10,
  },
  card: {
    ...theme.home.cart.detailPackage,
    width: '300px',
  },
  cardContent: {

    padding: '15px 15px 0',
  },
}));

const DetailPackage = (props) => {
  const {
    featuresPackage, anchorEl,
  } = props;
  const classes = useStyles();
  const open = Boolean(anchorEl);

  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disableRestoreFocus
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <List disablePadding>
            { featuresPackage && featuresPackage.map((item) => (
              <ListItem
                key={item.itemPackageId}
                divider
                dense
                disableGutters
              >
                <ListItemIcon style={{ minWidth: 0, marginRight: '5px' }}>
                  <img src={ckeckimage} alt="Logo" className={classes.iconBox} />
                </ListItemIcon>
                {
                    item.quantity === 0 ? (
                      <ListItemText
                        primaryTypographyProps={{ style: { fontSize: 10 } }}
                        primary={`${item.serviceFeature.serviceFeatureDescription}`}
                      />
                    ) : (
                      <ListItemText
                        primaryTypographyProps={{ style: { fontSize: 10 } }}
                        primary={`${item.quantity === 1000000 ? '(Ilimitadas)' : item.quantity} ${item.serviceFeature.serviceFeatureDescription}`}
                      />
                    )
                  }
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Popover>
  );
};

export default DetailPackage;
