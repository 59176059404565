/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Rules } from '../../../helpers/RHFRules';
import { getApplicationName } from '../../../helpers/generalUtils';

import {
  validationMailCodeAction,
  approveMailCodeAction,
  checkIsValidEmailAction,
  changeConfirmEmailAction,
} from '../../../actions/purchaseLicense.action';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.statusLicense.containerMessage.backgroundColor,
  },
  form: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
  },
  dialogTitle: {
    backgroundColor: theme.statusLicense.containerMessage.backgroundColor,
  },
  resendEmailLink: {
    ...theme.resendEmailLink,
  },
  titleModal: {
    ...theme.home.cart.title,
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  spacingContent: {
    paddingBottom: 10,
  },
  formErrors: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(13),
  },
  btn: {
    ...theme.verifyEmailLink,
    marginLeft: 10,
    fontSize: 14,
  },
}));

const ValidationCodeModal = (props) => {
  const classes = useStyles();
  const {
    isOpen, onClose,
    email, validationMailCode,
    validityCode, approveMailCode,
    getValidationCodeError, appName,
    checkIsValidEmail, changeConfirmEmail,
    confirmEmail,
  } = props;

  const [loading, setLoading] = useState(false);
  const [resendEmailCode, setResendEmailCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [resendMail, setResendMail] = useState('');

  const {
    handleSubmit, setValue, getValues, errors, control,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (formData) => {
    setErrorMessage('');
    const vEmail = emailConfirm || email;
    validationMailCode(vEmail, formData.validationCode, setLoading);
  };

  const resendEmail = () => {
    setResendEmailCode(true);
    const vEmail = emailConfirm || email;
    setResendMail(vEmail);
  };

  const handleResendEmail = () => {
    changeConfirmEmail(getValues('email'));
    checkIsValidEmail(getValues('email'), true, appName);
  };

  const handleClearError = () => {
    setErrorMessage('');
  };

  const handleBackSendCode = () => {
    setResendEmailCode(false);
    setErrorMessage('');
    setResendMail('');
  };

  useEffect(() => {
    const vEmail = emailConfirm || email;
    setEmailConfirm(vEmail);
  }, []);

  useEffect(() => {
    if (resendMail !== '') {
      setValue('email', resendMail);
    }
  }, [resendMail]);

  useEffect(() => {
    if (validityCode === true) {
      approveMailCode();
    }
  }, [validityCode]);

  useEffect(() => {
    if (getValidationCodeError) {
      setErrorMessage(getValidationCodeError);
      setValue('validationCode', '');
    }
  }, [getValidationCodeError]);

  useEffect(() => {
    if (confirmEmail) {
      setValue('email', confirmEmail);
      setEmailConfirm(confirmEmail);
    }
  }, [confirmEmail]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
      >
        <DialogTitle className={classes.dialogTitle}>
          {
            resendEmailCode && resendEmailCode === true ? (
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleBackSendCode}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            ) : (
              <>
              </>
            )
          }
          <Typography className={classes.titleModal}>
            {
              resendEmailCode && resendEmailCode === true ? (
                '¡Hecha un vistazo a tu bandeja de entrada!'
              ) : (
                '¡Solo falta una comprobación mas!'
              )
            }
          </Typography>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.root}>
          <Grid item xs={12} sm={12} className={classes.gridItem}>
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid item xs={12}>
                {
                resendEmailCode && resendEmailCode === true ? (
                  <Typography className={classes.spacingContent}>
                    {`¿Hemos acertado? esta es la dirección de email que utilizas para ${getApplicationName(appName)}.`}
                  </Typography>
                ) : (
                  <Typography className={classes.spacingContent}>
                    {`Te hemos enviado un código a ${emailConfirm}, por favor ingrésalo a continuación
                    (puede llegar a tu correo no deseado).`}
                  </Typography>
                )
                }
              </Grid>
              <Grid item xs={12} className={classes.spacingContent}>
                {
                  resendEmailCode && resendEmailCode === true ? (
                    <Controller
                      as={(
                        <TextField
                          size="medium"
                          variant="outlined"
                          label="Email"
                          required
                          fullWidth
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.email ? errors.email.message : ''}
                        />
                      )}
                      name="email"
                      control={control}
                      rules={Rules.email}
                      error={Boolean(errors.email)}
                      inputProps={{
                        maxLength: 100,
                        style: { textAlign: 'center', fontSize: '16px', fontWeight: 700 },
                      }}
                      defaultValue=""
                    />
                  ) : (
                    <>
                      <Controller
                        as={(
                          <TextField
                            size="medium"
                            variant="outlined"
                            label="Código de verificación"
                            required
                            fullWidth
                            FormHelperTextProps={{ style: { marginRight: 10 } }}
                            helperText={errors.validationCode ? errors.validationCode.message : ''}
                          />
                        )}
                        name="validationCode"
                        control={control}
                        rules={Rules.codigoValidacion}
                        error={Boolean(errors.validationCode)}
                        defaultValue=""
                        inputProps={{
                          maxLength: 6,
                          style: { textAlign: 'center', fontSize: '20px', fontWeight: 700 },
                        }}
                        onKeyDown={(e) => handleClearError(e)}
                      />
                      {getValidationCodeError && (
                        <Typography className={classes.formErrors}>
                          {errorMessage}
                        </Typography>
                      )}
                    </>
                  )
                }
              </Grid>
              <Grid item xs={12} className={classes.spacingContent}>
                {
                resendEmailCode && resendEmailCode === true ? (
                  <>
                  </>
                ) : (
                  <Link
                    component="button"
                    style={{ fontWeight: 'bold', fontSize: '15', paddingTop: '15px' }}
                    color="primary"
                    onClick={() => { resendEmail(); }}
                    className={classes.resendEmailLink}
                  >
                    ¿No has recibido el email?
                  </Link>
                )
                }
              </Grid>
              <Grid item xs={12}>
                {
                  resendEmailCode && resendEmailCode === true ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      onClick={() => {
                        handleResendEmail();
                      }}
                    >
                      Reenviar
                      {loading && <CircularProgress color="secondary" size={25} />}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                    >
                      Verificar
                      {loading && <CircularProgress color="secondary" size={25} />}
                    </Button>
                  )
                }
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (props) => ({
  validityCode: props.purchaseLicense.validityCode,
  confirmEmail: props.purchaseLicense.confirmEmail,
  getValidationCodeError: props.purchaseLicense.getValidationCodeError,
});

const mapDispatchToProps = {
  validationMailCode: validationMailCodeAction,
  approveMailCode: approveMailCodeAction,
  checkIsValidEmail: checkIsValidEmailAction,
  changeConfirmEmail: changeConfirmEmailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationCodeModal);
