export const LOADING = 'GENERAL_LOADING';
export const GENERAL_SHOW_MESSAGE = 'GENERAL_SHOW_MESSAGE';
export const GET_DOCUMENT_TYPE = 'GET_DOCUMENT_TYPE';
export const GET_PARAMETER_COMBO = 'GET_PARAMETER_COMBO';
export const GET_ASSISTANT_ADDRESS = 'GET_ASSISTANT_ADDRESS';
export const GET_STATES_LIST = 'GET_STATES_LIST';
export const SET_PURCHASE_PARAMETERS = 'GENERAL_SET_PURCHASE_PARAMETERS';
export const SET_REJECT_TYPES = 'SET_REJECT_TYPES';
export const SET_APP_NAME = 'GENERAL_SET_APP_NAME';
export const SET_SERVICE_TYPES = 'SET_SERVICE_TYPES';
export const GET_FEATURE_TYPES = 'GET_FEATURE_TYPES';
export const SET_VALUE_TAB = 'SET_VALUE_TAB';
export const GENERAL_SET_FILTERS_PAGE = 'GENERAL_SET_FILTERS_PAGE';
