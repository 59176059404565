/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core/';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import { useSnackbar } from 'notistack';
import headerBlue from '../../assets/img/sliderCard/blue01.svg';
import headerGreen from '../../assets/img/sliderCard/green.svg';
import headerYellow from '../../assets/img/sliderCard/yellow.svg';
import headerYellow01 from '../../assets/img/sliderCard/yellow01.svg';
import headerYellow02 from '../../assets/img/sliderCard/yellow02.svg';
import headerYellow03 from '../../assets/img/sliderCard/yellow03.svg';
import headerYellow04 from '../../assets/img/sliderCard/yellow04.svg';
import headerOrange from '../../assets/img/sliderCard/orange.svg';
import headerOrange01 from '../../assets/img/sliderCard/orange01.svg';
import headerOrange02 from '../../assets/img/sliderCard/orange02.svg';
import headerOrange03 from '../../assets/img/sliderCard/orange03.svg';
import headerOrange04 from '../../assets/img/sliderCard/orange04.svg';
import headerRed01 from '../../assets/img/sliderCard/red01.svg';
import headerRed02 from '../../assets/img/sliderCard/red02.svg';
import headerRed03 from '../../assets/img/sliderCard/red03.svg';
import headerRed04 from '../../assets/img/sliderCard/red04.svg';
import headerGreen01 from '../../assets/img/sliderCard/green01.svg';
import headerGray from '../../assets/img/sliderCard/gray.svg';
import ckeckimage from '../../assets/img/iconos/ibuhoo/ckeckimage.svg';
import packageCrown from '../../assets/img/sliderCard/packageCrown.svg';

import {
  addToCartAction,
  directPurchaseAction,
  applyPromotionCodeAction,
} from '../../actions/home.action';

import { CalcDiscountPackage } from '../../helpers/cartUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 12,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '35px',
    boxShadow: '2px 2px 2px -2px rgba(75,75,75,0.34)',
  },
  cardContent: {
    padding: 0,
  },
  cardHeader: {
    // backgroundImage: `url(${header})`,
    backgroundRepeat: 'no-repeat',
    height: '140px',
    padding: '10px 20px 0',
    color: 'white',
    fill: '#FFFFF',
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: 1,
  },
  subtitle: {
    fontSize: '22px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  text: {
    fontSize: '19px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  cardList: {
    padding: 10,
    fontSize: 10,
    letterSpacing: '0px',
    lineheight: 1,
  },
  btnDirectPurchase: {
    fontWeight: 900,
    '& .MuiButton-label': {
      fontWeight: 900,
      fontSize: '14px',
    },
  },
  addToCartButton: {
    width: 37,
    minWidth: 0,
  },
  iconBox: {
    width: 15,
    height: 15,
  },
}));

const SliderCard = (props) => {
  const {
    card, showList = true, showActions = true, showHeader = true, addToCart, directPurchase,
    isbgColor, colorBgSlider, colorBgHeader, textColor, hideAddCart, isPublic, buttonColor = 'primary',
    buttonTextColor = 'primary', applyPromotionCode, promoCode,
  } = props;
  const { packageName, itemsPackageFeatures: listItems } = card;
  const classes = useStyles();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const textToShow = (item) => {
    if (item.applyOffer) {
      return (
        <>
          {
            item.packagePrice > 0 && (
              <s style={{ fontSize: 11 }}>{`$${item.packagePrice.toLocaleString('en')}`}</s>
            )
          }
          <span>{` $${item.packagePriceWithDiscount.toLocaleString('en')}/anual`}</span>
        </>
      );
    }
    return `$${item.packagePrice.toLocaleString('en')}/anual`;
  };

  const getBackgroundImage = () => {
    switch (colorBgHeader) {
      case 'blue':
        return headerBlue;
      case 'green':
        return headerGreen;
      case 'green01':
        return headerGreen01;
      // case 'green02':
      //   return headerGreen02;
      // case 'green03':
      //   return headerGreen03;
      // case 'green04':
      //   return headerGreen04;
      case 'yellow':
        return headerYellow;
      case 'yellow1':
        return headerYellow01;
      case 'yellow2':
        return headerYellow02;
      case 'yellow3':
        return headerYellow03;
      case 'yellow4':
        return headerYellow04;
      case 'orange':
        return headerOrange;
      case 'orange01':
        return headerOrange01;
      case 'orange02':
        return headerOrange02;
      case 'orange03':
        return headerOrange03;
      case 'orange04':
        return headerOrange04;
      case 'red1':
        return headerRed01;
      case 'red2':
        return headerRed02;
      case 'red3':
        return headerRed03;
      case 'red4':
        return headerRed04;
      case 'gray':
        return headerGray;
      default:
        return headerBlue;
    }
  };

  const handleAddToCart = () => {
    card.discount = card.applyOffer ? CalcDiscountPackage(card.packageUnitPrice, card.totalOffer, card.offerTypeCode) : 0;
    addToCart(card, enqueueSnackbar);

    if (promoCode) {
      const data = {
        PromotionCode: promoCode.promoCode,
      };
      applyPromotionCode(data, null, null, false);
    }
  };

  const handleDirectPurchase = () => {
    card.discount = card.applyOffer ? CalcDiscountPackage(card.packageUnitPrice, card.totalOffer, card.offerTypeCode) : 0;
    directPurchase(card, enqueueSnackbar);

    if (promoCode) {
      const data = {
        PromotionCode: promoCode.promoCode,
      };
      applyPromotionCode(data, null, null, false);
    }
  };

  const getBackgroundColorCard = () => theme.home.backgroundSliderCard.color;

  return (
    <Card className={classes.root} variant="outlined" style={{ minHeight: showList ? 568 : 215, backgroundColor: isbgColor ? colorBgSlider : getBackgroundColorCard() }}>
      <CardContent className={classes.cardContent}>
        {showHeader
          && (

            <Box className={classes.cardHeader} style={{ backgroundImage: `url(${getBackgroundImage()})` }}>
              <Grid container item xs={12}>
                <Grid item xs={11}>
                  <Typography className={classes.title} style={{ color: textColor }}>{packageName}</Typography>
                  {/* <Typography className={classes.subtitle} style={{ fontWeight: 'bold', color: textColor }}>{subtitleToShow(card)}</Typography> */}
                  <Typography className={classes.text} style={{ fontWeight: 'bold', color: textColor }}>{textToShow(card)}</Typography>
                </Grid>
                <Grid item xs={1} style={{ textAlign: '-webkit-right' }}>
                  {!isPublic && (
                  <img src={packageCrown} alt="Crown" />
                  )}
                </Grid>
              </Grid>
            </Box>

          )}
        {showList
          && (
            <Box className={classes.cardList}>
              <List dense disablePadding>
                {listItems.map((item) => (
                  <ListItem
                    key={item.itemPackageId}
                    divider
                    dense
                    disableGutters
                  >
                    <ListItemIcon style={{ minWidth: 0, marginRight: '10px' }}>
                      <img src={ckeckimage} alt="Logo" className={classes.iconBox} />
                    </ListItemIcon>
                    {
                      item.quantity === 0 ? (
                        <ListItemText primary={`${item.serviceFeature.serviceFeatureDescription}`} />) : (
                          <ListItemText primary={`${item.quantity === 1000000 ? '(Ilimitadas)' : item.quantity} ${item.serviceFeature.serviceFeatureDescription}`} />)
                    }
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
      </CardContent>
      {
        showActions && (
          <CardActions style={{ justifyContent: 'center' }}>
            <Tooltip title="Agregar a compra">
              <Button
                variant="contained"
                color={buttonColor}
                className={classes.btnDirectPurchase}
                style={{ color: buttonTextColor, fontWeight: 900 }}
                onClick={() => handleDirectPurchase()}
              >
                ¡COMPRAR YA!
              </Button>
            </Tooltip>
            {!hideAddCart
            && (
            <Tooltip title="Agregar al carrito">
              <Button
                variant="contained"
                color={buttonColor}
                className={classes.addToCartButton}
                onClick={() => handleAddToCart()}
              >
                <Badge
                  overlap="rectangular"
                  badgeContent={(
                    <AddCircleOutlineTwoToneIcon
                      color="primary"
                      style={{
                        color: buttonTextColor, backgroundColor: 'white', borderRadius: '10px',
                      }}
                      fontSize="small"
                    />
                  )}
                >
                  <ShoppingCartIcon style={{ color: buttonTextColor }} />
                </Badge>
              </Button>
            </Tooltip>
            )}
          </CardActions>
        )
      }
    </Card>
  );
};

const mapStateToProps = ({ home }) => ({
  cart: home.cart,
  promoCode: home.promoCode,
});

const mapDispatchToProps = {
  addToCart: addToCartAction,
  directPurchase: directPurchaseAction,
  applyPromotionCode: applyPromotionCodeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderCard);
