/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/Money';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutocompleteGoogleMaps from '../../components/form/AutocompeteGoogleMaps/CustomAutocompleteGoogleMaps';
import { Rules } from '../../helpers/RHFRules';
import Loading from '../../components/crosscutting/Loading';
import AlertConfirm from '../../components/crosscutting/AlertConfirm';
import SwitchiOS from '../../components/form/SwitchiOS';
import ValidationCodeModal from '../../components/application/ValidationCode/ValidationCodeModal';

import {
  savePurchaseAction, getCustomerInformationAction,
  getNameByTypeDocAndDocAction, checkIsValidEmailAction,
  validityChangeCustomerAction, setMessageAlertAction,
  resetFormRegisterAction,
} from '../../actions/purchaseLicense.action';
import { setStatusCart } from '../../actions/home.action';
import { getPurchaseOrderParametersAction, getTokenExternalSourceAction, getParameterByTypeAction } from '../../actions/general.action';
import { CleanCart } from '../../helpers/cartUtils';
import { calculateCheckDigit } from '../../helpers/generalUtils';
import { setGeoCode } from '../../helpers/googleMapsUtils';
import { getLocalStorageEncript } from '../../helpers/storageUtils';
import { setMessage } from '../../helpers/purchaseUtils';
import { getDocumentTypes } from '../../helpers/documentsTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '30px',
    marginBottom: '10px',
    padding: '20px',
    ...theme.registerForm.masterContainer,
  },
  titleForm: {
    ...theme.registerForm.titleForm,
    paddingTop: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.palette.primary.main,
    ...theme.registerForm.containerForm,
  },
  helpIconColor: {
    ...theme.home.cart.helpIconColor,
  },
  goHomeLink: {
    ...theme.registerForm.goHomeLink,
  },
  helpIconColorLicense: {
    ...theme.home.cart.helpIconColorLicense,
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  contentTextPrimary: {
    color: theme.palette.primary.main,
  },
  contentTextSecondary: {
    color: theme.registerForm.contentTextSecondary.main,
  },
  submit: {
    textAlign: 'center',
    padding: 10,
  },
  typeInput: {
    '& .MuiOutlinedInput-root': {
      ...theme.registerForm.typeInput,
    },
    '& .MuiOutlinedInput-input': {
      font: 'caption',
      fontSize: '15px',
    },
  },
  btn: {
    ...theme.registerForm.btnCancel,
    marginLeft: 10,
    fontSize: 14,
  },
  btnResponsive: {
    ...theme.registerForm.btnResponsive,
    marginLeft: 10,
    fontSize: 14,
    width: '100%',
    height: '50px',
    marginTop: '17px',
  },
  btnCancelResponsive: {
    ...theme.home.cart.btnCancelResponsive,
    marginLeft: 10,
    fontSize: 16,
    width: '100%',
    height: '50px',
    marginTop: '17px',
  },
  panelSummary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.panelSummary.color,
    maxHeight: '50px',
    borderRadius: 4,
    marginTop: '16px',
  },
  expanded: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.panelSummary.color,
    borderRadius: 4,
    borderColor: theme.palette.secondary.main,
    '&$expanded': {
      minHeight: '0px',
      maxHeight: '50px',
    },
  },
  infoText: {
    color: theme.registerForm.checkDigit.main,
    marginTop: -23,
    fontSize: theme.typography.pxToRem(16),
    position: 'absolute',
  },
  legalTerms: {
    padding: '20px',
  },
  formErrors: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(13),
  },
  checkOptionFilter: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      ...theme.checkOptionFilter,
    },
    '&.MuiCheckbox-colorSecondary':{
      '&:hover': {
        ...theme.checkOptionHover,
      },
    },
  },
  policyAndTerms: {
    ...theme.registerForm.activatePlan.policyAndTerms,
    fontSize: 14,
  },
  buttonProgress: {
    color: theme.palette.error.main,
    top: '10px',
    marginLeft: '15px',
    position: 'relative',
  },
  expansionPanelTitle: {
    ...theme.registerForm.containerForm.expansionPanel,
    fontWeight: 'bold',
    fontSize: 16,
  },
  expansionPanelIcon: {
    ...theme.registerForm.containerForm.expansionPanel,
  },
  btnCashPayment: {
    display: 'none',
  },
  secondaryMessageAlert: {
    textAlign: 'center',
    color: theme.alertPopup.secondaryMessageText.color,
    paddingTop: 15,
    paddingBottom: 20,
  },
  controlLabel: {
    ...theme.registerForm.controlLabel,
  },
}));

const HelpTooltip = withStyles((theme) => ({
  tooltip: {
    ...theme.helpToolTip.root,
    fontSize: 12,
    fontWeight: 500,
  },
}))(Tooltip);

const personType = [
  { value: 'J', label: 'Persona Juridica' },
  { value: 'N', label: 'Persona Natural' },
];

const subtotal = (cart) => cart.reduce(
  (previousValue, currentValue) => (
    (((currentValue.base * currentValue.number) - currentValue.discount) + currentValue.taxiva) + previousValue
  ), 0,
);

const RegisterPurchaseOrder = (props) => {
  const classes = useStyles();
  const {
    loading, loadingGral, getError, userInfo, appName,
    savePurchase, getPurchaseOrderParameters,
    documentTypes, statesAndCities, applicationInfo,
    billingInfo, serviceInfo, typeLoad, getCustomerInformation,
    cart, history, setIsCartOpen, getNameByTypeDocAndDoc, approvedValidityEmail,
    getTokenExternalSource, checkIsValidEmail, validityEmail, validityCode, confirmEmail,
    validityChangeCustomer, validityChangeCustomerAct, referenceUuidPurchaseOrder,
    showMessageAlert, setMsgAlert, resetFormRegister, citiesBillGoogleAssistant,
    citiesServGoogleAssistant, getParameterByType, parameterCombo,
  } = props;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    handleSubmit, errors, control, setValue, getValues, watch, setError,
  } = useForm({
    mode: 'onChange',
  });
  const watchDocTypeBill = watch('documentTypeIdBilling');
  const watchDocTypeSer = watch('documentTypeIdService');
  const watchLegalDocTypeSer = watch('legalDocumentTypeIdService');
  const watchDocBill = watch('documentNumberBilling');
  const watchDocSer = watch('documentNumberService');
  const watchDocLeg = watch('legalDocumentNumberService');
  const watchEmailSer = watch('emailService');

  const [departaments, setDepartaments] = useState([]);
  const [citiesBilling, setCitiesBilling] = useState([]);
  const [citiesService, setCitiesService] = useState([]);
  const [dataForm, setFormData] = React.useState({});
  const [personTypeBilling, setPersonTypeBilling] = React.useState('J');
  const [personTypeService, setPersonTypeService] = React.useState('J');
  const [check, setCheck] = useState(Boolean(Object.keys(billingInfo).lenght));
  const [checkService, setCheckService] = useState(Boolean(Object.keys(serviceInfo).lenght));
  const [paymentMethod, setPaymentMethod] = React.useState('');
  const [openAlertInfo, setOpenAlertInfo] = useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, setTitleAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [messageData, setMessageData] = useState(null);
  const [textButtonAccept, setTextButtonAccept] = useState('');
  const [showCloseAlert, setShowCloseAlert] = useState(false);
  const [showButtonCancel, setShowButtonCancel] = useState(false);
  const [openValidationCodeMail, setOpenValidationCodeMail] = useState(false);
  const [loadingRues, setLoadingRues] = React.useState(false);
  const [confirmEmailServ, setConfirmEmailServ] = React.useState('');
  const [valueDepartamentBilling, setValueDepartamentBilling] = React.useState(null);
  const [valueCityBilling, setValueCityBilling] = React.useState(null);
  const [valueDepartamentService, setValueDepartamentService] = React.useState(null);
  const [valueCityService, setValueCityService] = React.useState(null);
  const [geoCodeBilling, setGeoCodeBilling] = React.useState({ lat: null, lng: null });
  const [geoCodeService, setGeoCodeService] = React.useState({ lat: null, lng: null });
  const [addressMapsBilling, setAddressMapsBilling] = React.useState(null);
  const [inputAddressBillingValue, setInputAddressBillingValue] = React.useState('');
  const [optionsAddressBilling, setOptionsAddressBilling] = React.useState([]);
  const [addressMapsService, setAddressMapsService] = React.useState(null);
  const [inputAddressServiceValue, setInputAddressServiceValue] = React.useState('');
  const [optionsAddressService, setOptionsAddressService] = React.useState([]);
  const [changeValues, setChangeValues] = React.useState('');
  const [emailServiceInput, setEmailServiceInput] = React.useState(null);

  // ---------------------------------------------
  // method - carga de datos de cliente
  // ---------------------------------------------
  const loadCustomerData = (type) => {
    if (userInfo) {
      const data = {
        documentType: userInfo.customer_type,
        documentNumber: userInfo.customer_value,
      };
      getCustomerInformation(data, type);
    }
  };

  useEffect(() => {
    if (statesAndCities) {
      setDepartaments(statesAndCities.itemsStatesList.sort((a, b) => {
        const x = a.text < b.text ? -1 : 1;
        return x;
      }));
      if (userInfo) {
        setCheck(true);
        setCheckService(true);
        loadCustomerData();
      }
    }
  }, [statesAndCities]);

  useEffect(() => {
    if (validityChangeCustomer === false) {
      setMsgAlert({
        category: 'changeCustomer',
        typeMsg: 'Info',
        tittleMsg: '',
        message: 'Recuerde que si modifica estos datos la licencia quedara registrada con esta información y no con la del formulario de facturación',
        showCancelButton: true,
      });
    }
  }, [validityChangeCustomer]);

  // ---------------------------------------------
  // method - Remover espacios texto
  // ---------------------------------------------
  const removeSpaces = (nameDoc) => {
    let docNumAux = getValues(nameDoc);
    if (docNumAux) {
      if (docNumAux.match(/\s/g)) {
        docNumAux = docNumAux.replace(/\s/g, '');
      }
      setValue(nameDoc, docNumAux);
    }
  };

  // ---------------------------------------------
  // method - Obtiene digito de verificación
  // ---------------------------------------------
  const getCheckDigit = (dv, nameDoc, typeDoc) => {
    const numero = getValues(nameDoc);
    if (typeDoc === 4 && numero && numero.length > 5) {
      const checkDigit = calculateCheckDigit(numero);
      if (checkDigit || checkDigit === 0) {
        setValue(dv, checkDigit);
      }
    } else {
      setValue(dv, '');
    }
  };

  useEffect(() => {
    removeSpaces('documentNumberBilling');
  }, [watchDocBill]);

  useEffect(() => {
    removeSpaces('documentNumberService');
  }, [watchDocSer]);

  useEffect(() => {
    removeSpaces('legalDocumentNumberService');
  }, [watchDocLeg]);

  useEffect(() => {
    if (changeValues !== '') {
      setValue('dvService', getValues('dvBilling'));
      setValue('legalNameService', getValues('legalNameBilling'));
    }
  }, [changeValues]);

  useEffect(() => {
    if (watchEmailSer) {
      setEmailServiceInput(watchEmailSer);
    }
  }, [watchEmailSer]);
  

  const cleanFields = (name) => {
    setValue(`legalName${name}`, '');
    setValue(`firstName${name}`, '');
    setValue(`lastName${name}`, '');
  };

  // ---------------------------------------------
  // method - Obtiene datos de carga de rues clientes
  // ---------------------------------------------
  const getDefaulttNameByTypeAndDoc = async (personType, valueDocType, valueDoc, name) => {
    const doc = getValues(valueDoc);
    const docType = getValues(valueDocType);
    if (name === 'Service') {
      getCheckDigit('dvService', 'documentNumberService', watchDocTypeSer);
    } else {
      getCheckDigit('dvBilling', 'documentNumberBilling', watchDocTypeBill);
    }
    if (doc && (docType === 4 || docType === 1)) {
      setLoadingRues(true);
      const response = await getNameByTypeDocAndDoc(personType, docType, doc, appName);
      cleanFields(name);
      if (response) {
        if (personType === 'J') {
          setValue(`legalName${name}`, response);
        } else {
          setValue(`firstName${name}`, response[0]);
          if (response[1]) {
            setValue(`lastName${name}`, response[1]);
          }
        }
      }
      setLoadingRues(false);
    }
  };

  // ---------------------------------------------
  // effect - Obtiene datos de carga inicial de formulario
  // ---------------------------------------------
  useEffect(() => {
    const parameters = getLocalStorageEncript('parametersApp') ? JSON.parse(getLocalStorageEncript('parametersApp')) : null;
    if (cart.length) {
      resetFormRegister();
      getTokenExternalSource();
      getPurchaseOrderParameters();
    } else if (appName === 'colnomina' || appName === 'firmaya' || appName === 'superguarda') {
      if (parameters?.application?.urlLandingPage) {
        window.location.href = parameters?.application?.urlLandingPage;
      }
    } else {
      history.push('/');
    }
  }, []);

  // ---------------------------------------------
  // method - Setear direccion facturacion / Servicio
  // ---------------------------------------------
  const setAddressCustomer = (valueAddress, setOptionsMaps, setAddressValue, combo, geoCode) => {
    if (valueAddress) {
      const optionsAddress = [];

      optionsAddress.push({
        description: valueAddress,
        structured_formatting: {
          main_text: valueAddress,
          main_text_matched_substrings: [
            {
              length: 0,
              offset: 0,
            },
          ],
          secondary_text: '',
        },
      });

      setOptionsMaps(optionsAddress);
      if (optionsAddress.length > 0) {
        setAddressValue(optionsAddress[0]);
        setValue(combo, optionsAddress[0]);
        setGeoCode(valueAddress, geoCode);
      }
    }
  };

  // ---------------------------------------------
  // method - Setear ciudad cliente facturacion
  // ---------------------------------------------
  const setCityCustomerBillingId = (dptoId, dptoName, cityId, cityName, checkBill) => {
    setValueDepartamentBilling(null);
    setValueCityBilling(null);
    if (checkBill) {
      let dptoBilling = {};
      if (dptoId > 0) {
        dptoBilling = statesAndCities.itemsStatesList.find((d) => d.value === dptoId);
      } else {
        dptoBilling = statesAndCities.itemsStatesList.find((d) => d.text === dptoName);
        // dptoBilling = statesAndCities.itemsStatesList.find((d) => dptoName.include(d.text));
      }

      if (dptoBilling) {
        setValueDepartamentBilling(dptoBilling);
        setValue('departmentIdBilling', dptoBilling);
      }

      let newCities = {};
      if (dptoId > 0) {
        newCities = statesAndCities.statesList.find((d) => d.id === dptoId);
      } else {
        newCities = statesAndCities.statesList.find((d) => d.stateName === dptoName);
      }
      if (newCities) {
        setCitiesBilling(newCities.city);

        let cityBilling = {};
        if (cityId > 0) {
          cityBilling = newCities.city.find((d) => d.id === cityId);
        } else {
          cityBilling = newCities.city.find((d) => d.cityName === cityName);
        }

        if (cityBilling) {
          setValueCityBilling(cityBilling);
          setValue('departmentIdBilling', dptoBilling.value);
          setValue('cityIdBilling', cityBilling.id);
        }
      }
    }
  };

  // ---------------------------------------------
  // method - Setear ciudad cliente servicio
  // ---------------------------------------------
  const setCityCustomerServiceId = (dptoId, dptoName, cityId, cityName, checkServ) => {
    setValueDepartamentService(null);
    setValueCityService(null);
    if (checkServ) {
      let dptoServ = {};
      if (dptoId > 0) {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.value === dptoId);
      } else {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.text === dptoName);
        // dptoBilling = statesAndCities.itemsStatesList.find((d) => dptoName.include(d.text));
      }

      if (dptoServ) {
        setValueDepartamentService(dptoServ);
        setValue('departmentIdService', dptoServ);
      }

      let newCities = {};
      if (dptoId > 0) {
        newCities = statesAndCities.statesList.find((d) => d.id === dptoId);
      } else {
        newCities = statesAndCities.statesList.find((d) => d.stateName === dptoName);
      }
      if (newCities) {
        setCitiesService(newCities.city);

        let cityBilling = {};
        if (cityId > 0) {
          cityBilling = newCities.city.find((d) => d.id === cityId);
        } else {
          cityBilling = newCities.city.find((d) => d.cityName === cityName);
        }

        if (cityBilling) {
          setValueCityService(cityBilling);
          setValue('departmentIdService', dptoServ.value);
          setValue('cityIdService', cityBilling.id);
        }
      }
    }
  };

  useEffect(() => {
    if (!typeLoad || typeLoad === 'Billing') {
      setValue('personTypeBilling', check ? billingInfo.personType : '');
      setPersonTypeBilling(billingInfo.personType);
      const documentTypeBilling = (appName === 'colfactura' || appName === 'colnomina')
        ? billingInfo.documentTypeColfacturaId
        : billingInfo.documentTypeId;
      setValue('documentTypeIdBilling', check ? documentTypeBilling : '');
      setValue('documentNumberBilling', check ? billingInfo.documentNumber : '');
      setValue('dvBilling', check ? billingInfo.dv : '');
      setValue('legalNameBilling', check ? billingInfo.legalName : '');
      setValue('firstNameBilling', check ? billingInfo.firstName : '');
      setValue('lastNameBilling', check ? billingInfo.lastName : '');
      setValue('cellphoneNumberBilling', check ? billingInfo.cellphoneNumber : '');
      setValue('emailBilling', check ? billingInfo.email : '');
      setValue('departmentIdBilling', check ? billingInfo.departmentId : '');
      if (statesAndCities) {
        setCityCustomerBillingId(billingInfo.departmentId, null, billingInfo.cityId, null, check);
      }
      setValue('cityIdBilling', check ? billingInfo.cityId : '');
      if (check) {
        setAddressCustomer(billingInfo.fullAddress, setOptionsAddressBilling, setAddressMapsBilling, 'fulladdressBilling', setGeoCodeBilling);
      } else {
        setValue('fulladdressBilling', '');
        setOptionsAddressBilling([]);
        setAddressMapsBilling(null);
      }
      setValue('additionalInfoBilling', check ? billingInfo.addressAdditionalInformation : '');
    }
  }, [billingInfo]);

  useEffect(() => {
    if (!typeLoad || typeLoad === 'Service') {
      setValue('personTypeService', checkService ? serviceInfo.personType : '');
      setPersonTypeService(serviceInfo.personType);
      const documentTypeService = (appName === 'colfactura' || appName === 'colnomina')
        ? serviceInfo.documentTypeColfacturaId
        : serviceInfo.documentTypeId;
      setValue('documentTypeIdService', checkService ? documentTypeService : '');
      setValue('documentNumberService', checkService ? serviceInfo.documentNumber : '');
      setValue('dvService', checkService ? serviceInfo.dv : '');
      setValue('legalNameService', checkService ? serviceInfo.legalName : '');
      setValue('firstNameService', checkService ? serviceInfo.firstName : '');
      setValue('lastNameService', checkService ? serviceInfo.lastName : '');
      setValue('cellphoneNumberService', checkService ? serviceInfo.cellphoneNumber : '');
      setValue('emailService', checkService ? serviceInfo.email : '');
      setValue('departmentIdService', checkService ? serviceInfo.departmentId : '');
      if (statesAndCities) {
        setCityCustomerServiceId(serviceInfo.departmentId, null, serviceInfo.cityId, null, checkService);
      }
      setValue('cityIdService', checkService ? serviceInfo.cityId : '');
      if (checkService) {
        setAddressCustomer(serviceInfo.fullAddress, setOptionsAddressService, setAddressMapsService, 'fulladdressService', setGeoCodeService);
      } else {
        setValue('fulladdressService', '');
        setOptionsAddressService([]);
        setAddressMapsService(null);
      }
      setValue('legalDocumentTypeIdService', checkService ? serviceInfo.legalDocumentTypeId : '');
      setValue('legalDocumentNumberService', checkService ? serviceInfo.legalDocumentNumber : '');
      setValue('legalFirstNameService', checkService ? serviceInfo.legalFirstName : '');
      setValue('legalLastNameService', checkService ? serviceInfo.legalLastName : '');
      setValue('additionalInfoService', checkService ? serviceInfo.addressAdditionalInformation : '');
    }
  }, [serviceInfo]);

  const showError = (name, msj) => {
    setError(name, {
      type: 'manual',
      message: msj,
    });
  };

  // ---------------------------------------------
  // method - Guardar formulario de orden de compra
  // ---------------------------------------------
  const savePurchaseOrder = () => {
    const documentNumberService = getValues('documentNumberService');
    const documentNumberCustomer = serviceInfo.documentNumber;

    if (getValues('personTypeService') === 'J' && getValues('legalDocumentNumberService') === documentNumberService) {
      showError('legalDocumentNumberService', 'Nit y Número de Documento no deben ser iguales.');
    } else if (documentNumberCustomer && documentNumberCustomer !== documentNumberService) {
      validityChangeCustomerAct(documentNumberCustomer, documentNumberService);
    } else if (paymentMethod === 'EN_LINEA' || paymentMethod === 'SIN_PAGO') {
      savePurchase(dataForm, paymentMethod, appName, history, confirmEmailServ, geoCodeBilling, geoCodeService);
    } else {
      setMsgAlert({
        category: 'cashPaymentPurchase',
        typeMsg: 'Info',
        tittleMsg: 'Pago en efectivo',
        message: 'Para pagar en efectivo debes imprimir la orden de pedido que te vamos a enviar al correo y seguir las instrucciones que allí se especifican.',
        textButtonAcept: 'Enviar orden',
      });
    }
  };

  useEffect(() => {
    if (getError) {
      setOpenAlertInfo(true);
      const message = setMessage(getError, userInfo);
      setMessageData(message);
      setMsgAlert({
        category: message.category,
        typeMsg: 'Error',
        tittleMsg: message.title,
        message: message.message,
        textButtonAcept: message.acceptText,
        showCloseAlert: message.showCloseAlert,
        showCancelButton: message.showCancelButton,
      });
      // ToDo guardar log de error presentado
    }
  }, [getError]);

  useEffect(() => {
    if (validityEmail === true) {
      setOpenValidationCodeMail(false);
      savePurchaseOrder();
    } else if (validityEmail === false) {
      setOpenValidationCodeMail(true);
    }
  }, [validityEmail]);

  useEffect(() => {
    if (confirmEmail) {
      setConfirmEmailServ(confirmEmail);
    }
  }, [confirmEmail]);

  useEffect(() => {
    if (approvedValidityEmail) {
      setOpenValidationCodeMail(false);
      setOpenAlertInfo(true);
    }
  }, [approvedValidityEmail]);

  useEffect(() => {
    if (showMessageAlert) {
      setTypeAlert(showMessageAlert.category);
      setTitleAlert(showMessageAlert.tittleMsg);
      setMessageAlert(showMessageAlert.message);
      setShowCloseAlert(showMessageAlert.showCloseAlert ? showMessageAlert.showCloseAlert : false);
      setShowButtonCancel(showMessageAlert.showCancelButton ? showMessageAlert.showCancelButton : false);
      setTextButtonAccept(showMessageAlert.textButtonAcept ? showMessageAlert.textButtonAcept : 'Aceptar');
      setOpenAlertInfo(true);
    }
  }, [showMessageAlert]);

  useEffect(() => {
    if (citiesBillGoogleAssistant && citiesBillGoogleAssistant.deptoName !== null) {
      setCityCustomerBillingId(0, citiesBillGoogleAssistant.deptoName, 0, citiesBillGoogleAssistant.cityName, true);
    } else if (addressMapsBilling && optionsAddressBilling.length > 3 && !check) {
      setValueDepartamentBilling(null);
      setValueCityBilling(null);
      setValue('departmentIdBilling', '');
      setValue('cityIdBilling', '');
    }
  }, [citiesBillGoogleAssistant]);

  useEffect(() => {
    if (citiesServGoogleAssistant && citiesServGoogleAssistant.deptoName !== null) {
      setCityCustomerServiceId(0, citiesServGoogleAssistant.deptoName, 0, citiesServGoogleAssistant.cityName, true);
    } else if (addressMapsService && optionsAddressService.length > 3 && !checkService) {
      setValueDepartamentService(null);
      setValueCityService(null);
      setValue('departmentIdService', '');
      setValue('cityIdService', '');
    }
  }, [citiesServGoogleAssistant]);

  useEffect(() => {
    if (userInfo) {
      getParameterByType('InTouch');
    }
  }, []);

  // ---------------------------------------------
  // method - setear tipo de persona
  // ---------------------------------------------
  const setPersonType = (event) => {
    const { value } = event.target;

    if (event.target.name === 'personTypeBilling') {
      setPersonTypeBilling(value);
      if (value === 'J') {
        setValue('documentTypeIdBilling', 4);
      }
      getDefaulttNameByTypeAndDoc(value, 'documentTypeIdBilling', 'documentNumberBilling', 'Billing');
    } else if (event.target.name === 'personTypeService') {
      setPersonTypeService(value);
      if (value === 'J') {
        setValue('documentTypeIdService', 4);
      }
      getDefaulttNameByTypeAndDoc(value, 'documentTypeIdService', 'documentNumberService', 'Service');
    }
    return value;
  };


  // ---------------------------------------------
  // event envio de datos de formulario
  // ---------------------------------------------
  const onSubmit = (formData) => {
    checkIsValidEmail(getValues('emailService'), false, appName);
    setFormData(formData);
  };

  const handleChangeStateCustomerBillingId = (idDepartament) => {
    setValueCityBilling(null);
    const newCities = statesAndCities.statesList.find((d) => d.id === idDepartament);
    if (newCities) {
      setCitiesBilling(newCities.city.sort((a, b) => {
        const x = a.cityName < b.cityName ? -1 : 1;
        return x;
      }));
    }
    return idDepartament;
  };

  // ---------------------------------------------
  // event handler cambio de estado cliente servicio
  // ---------------------------------------------
  const handleChangeStateCustomerServiceId = (idDepartament) => {
    setValueCityService(null);
    const newCities = statesAndCities.statesList.find((d) => d.id === idDepartament);
    if (newCities) {
      setCitiesService(newCities.city.sort((a, b) => {
        const x = a.cityName < b.cityName ? -1 : 1;
        return x;
      }));
    }
    return idDepartament;
  };

  // ---------------------------------------------
  // obtener información usuario logueado
  // ---------------------------------------------
  const getUserInfo = (obj) => {
    if (obj.target.name === 'checkedUseCustomerBilling') {
      setCheck(!check);
      loadCustomerData('Billing');
    } else if (obj.target.name === 'checkedUseCustomerService') {
      if (userInfo) {
        setCheckService(!checkService);
        loadCustomerData('Service');
      } else {
        setCheckService(!checkService);
        if (!checkService) {
          setValue('personTypeService', getValues('personTypeBilling'));
          setPersonTypeService(getValues('personTypeBilling'));
          setValue('documentTypeIdService', (getValues('personTypeBilling') === 'N' && getValues('documentTypeIdBilling') === 4)
            ? 1
            : getValues('documentTypeIdBilling'));
          setValue('documentNumberService', getValues('documentNumberBilling'));
          if (getValues('documentTypeIdBilling') === 4) {
            setChangeValues('changeValue');
          }
          if (getValues('personTypeBilling') === 'J') {
            setChangeValues('changeValue');
          } else {
            setValue('firstNameService', getValues('firstNameBilling'));
            setValue('lastNameService', getValues('lastNameBilling'));
          }
          setValue('cellphoneNumberService', getValues('cellphoneNumberBilling'));
          setValue('emailService', getValues('emailBilling'));
          if (valueDepartamentBilling) {
            const departamentService = departaments.find((d) => d.value === valueDepartamentBilling.value);
            if (departamentService) {
              setValueDepartamentService(departamentService);
              handleChangeStateCustomerServiceId(valueDepartamentBilling.value);
              const newCitiesServ = statesAndCities.statesList.find((d) => d.id === valueDepartamentBilling.value);
              if (newCitiesServ) {
                setCitiesService(newCitiesServ.city);
                if (valueCityBilling) {
                  const cityServ = newCitiesServ.city.find((d) => d.id === valueCityBilling.id);
                  if (cityServ) {
                    setValueCityService(cityServ);
                  }
                  setValue('cityIdService', valueCityBilling.id);
                }
              }
              setValue('departmentIdService', valueDepartamentBilling.value);
            }
          }
          // carga direccion de servicio con seleccion de datos de datos de facturacion
          if (addressMapsBilling.description) {
            setAddressCustomer(addressMapsBilling.description, setOptionsAddressService, setAddressMapsService, 'fulladdressService', setGeoCodeService);
          }
          setValue('additionalInfoService', getValues('additionalInfoBilling'));
        } else {
          setValue('personTypeService', '');
          setValue('documentTypeIdService', '');
          setValue('documentNumberService', '');
          setValue('legalNameService', '');
          setValue('firstNameService', '');
          setValue('lastNameService', '');
          setValue('cellphoneNumberService', '');
          setValue('emailService', '');
          setValueDepartamentService(null);
          setValueCityService(null);
          setValue('departmentIdService', null);
          setValue('cityIdService', null);
          setValue('fulladdressService', '');
          setOptionsAddressService([]);
          setAddressMapsService(null);
          setValue('additionalInfoService', '');
          setChangeValues('');
        }
      }
    }
  };

  /**
   * Metodo ir a Url Security
   */
  const handleGoToSecurity = () => {
    window.location.href = applicationInfo.urlSecutityLogin;
  };

  /**
   * Metodo ir a landing Page
   */
  const handleGoToLandingPage = () => {
    if (applicationInfo?.urlLandingPage) {
      window.location.href = applicationInfo.urlLandingPage;
    }
  };

  // ---------------------------------------------
  // event handler boton aceptar componente alerta
  // ---------------------------------------------
  const handleClickOkAlert = () => {
    switch (typeAlert) {
      case 'registerCashPurchase':
        setOpenAlertInfo(false);
        if (referenceUuidPurchaseOrder) {
          // redirecciona a resumen de compra
          history.push(`/summaryorder/${referenceUuidPurchaseOrder}`);
        } else {
          // redirecciona a home de paquetes
          history.push('/');
        }
        break;
      case 'approveMailCode':
        // guarda orden de compra
        savePurchaseOrder();
        break;
      case 'cashPaymentPurchase':
        savePurchase(dataForm, paymentMethod, appName, history, confirmEmailServ, geoCodeBilling, geoCodeService);
        break;
      case 'changeCustomer':
        if (paymentMethod === 'EN_LINEA' || paymentMethod === 'SIN_PAGO') {
          savePurchase(dataForm, paymentMethod, appName, history, confirmEmailServ, geoCodeBilling, geoCodeService);
        } else {
          setMsgAlert({
            category: 'cashPaymentPurchase',
            typeMsg: 'Confirm',
            tittleMsg: 'Pago en efectivo',
            message: 'Para pagar en efectivo debes imprimir la orden de pedido que te vamos a enviar al correo y seguir las instrucciones que allí se especifican',
            textButtonAcept: 'Enviar orden',
          });
        }
        break;
      case 'errorRegisterPayment':
        resetFormRegister();
        setOpenAlertInfo(false);
        break;
      case 'buyOnlyOneLicense':
        setOpenAlertInfo(false);
        if (userInfo) {
          history.push('/myplan');
        } else {
          handleGoToSecurity();
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        break;
      case 'licensePendingActivation':
        setOpenAlertInfo(false);
        handleGoToLandingPage();
        break;
      default:
        break;
    }
  };

  // ---------------------------------------------
  // event handler boton cancelar componente alerta
  // ---------------------------------------------
  const handleClickCancelAlert = () => {
    switch (typeAlert) {
      case 'errorRegisterPayment':
        setOpenAlertInfo(false);
        break;
      case 'buyOnlyOneLicense':
        setOpenAlertInfo(false);
        handleGoToLandingPage();
        break;
      default:
        resetFormRegister();
        setOpenAlertInfo(false);
        break;
    }
  };

  // ---------------------------------------------
  // event handler boton close validation mail
  // ---------------------------------------------
  const handleCloseValidationMail = () => {
    setOpenValidationCodeMail(false);
  };

  // ---------------------------------------------
  // validación documento
  // ---------------------------------------------
  const validateDocument = (persontype, watchDocType) => {
    if (watchDocType === 6) {
      return Rules.pasaporte;
    } if (watchDocType === 4 && persontype === 'J') {
      return Rules.tipoDocumentoNIT;
    }
    return Rules.otrosDocumentos;
  };

  const validateDoc = (docType, e) => {
    const alfanumerico = /[a-zA-Z0-9]/gi;
    const numerico = /[0-9]/gi;
    if (docType === 6) {
      if (!e.key.match(alfanumerico)) {
        e.preventDefault();
      }
    } else if (!e.key.match(numerico)) {
      e.preventDefault();
    }
  };

  // ---------------------------------------------
  // event handler ingreso de teclas validas
  // ---------------------------------------------
  const handleKeyDown = (e, watchDocType) => {
    const { keyCode } = e;
    if (keyCode === 9 || keyCode === 8 || keyCode === 17 || keyCode === 37 || keyCode === 39 || keyCode === 46) return;
    validateDoc(watchDocType, e);
  };

  const getLoading = () => (
    <>
      <Grid item xs={1} sm={1}>
        <CircularProgress size={24} className={classes.buttonProgress} />
      </Grid>
    </>
  );

  const boxCheckDigit = (referencia) => (
    <>
      <Grid item xs={1} sm={1}>
        <Box pt={1} style={{ textAlign: 'center' }}>-</Box>
      </Grid>
      <Grid item xs={2} sm={2}>
        <Controller
          as={(
            <TextField
              size="small"
              label="Dv"
              disabled
              variant="outlined"
              style={{ color: 'red' }}
              className={classes.typeInput}
            />
          )}
          name={referencia}
          control={control}
          defaultValue={referencia === 'dvBilling' ? !typeLoad || typeLoad === 'Billing' ? check ? billingInfo.dv : '' : '': !typeLoad || typeLoad === 'Service' ? checkService ? Object.keys(serviceInfo).length > 0 ? serviceInfo.dv : '' : '' : ''}
        />
      </Grid>
    </>
  );

  const renderCustomAlert = () => (
    <>
      {
          messageData && messageData.minDateToRenewLicense && (
            <>
              <div style={{ textAlign: 'center' }}>
                <span style={{ fontSize: 15 }} className={classes.secondaryMessageAlert}>{messageData.customMessage}</span>
                <span style={{ fontWeight: 700, fontSize: 15 }} className={classes.secondaryMessageAlert}>{messageData.minDateToRenewLicense}</span>
              </div>
            </>
          )
        }
      {
          messageData && messageData.submessage && (
            <Typography className={classes.secondaryMessageAlert}>
              {messageData.submessage}
            </Typography>
          )
        }
    </>
  );

  // ---------------------------------------------
  // event handler boton cancelar formulario de registro de orden compra
  // ---------------------------------------------
  const handleCancelClick = () => {
    if (appName !== 'superguarda') {
      setIsCartOpen(true);
    }
    const redirectPage = localStorage.getItem('redirectPage');
    if (redirectPage === 'home') history.push('/');
    else if (redirectPage === 'myPlan') history.push('/myplan');
    else history.push('/');
  };

  // ---------------------------------------------
  // event handler ir a listado de paquetes
  // ---------------------------------------------
  const handleGoHome = () => {
    if (appName === 'colnomina' || appName === 'firmaya' || appName === 'superguarda') {
      if (applicationInfo?.urlLandingPage) {
        window.location.href = applicationInfo?.urlLandingPage;
      }
    } else {
      history.push('/');
    }
  };

  // ---------------------------------------------
  // Bloqueo tecla enter
  // ---------------------------------------------
  const skipEnterPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  if (loading) return <Loading />;
  if (loadingGral) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Link
          component="button"
          style={{ fontWeight: 'bold', fontSize: '15', paddingTop: '15px' }}
          color="primary"
          onClick={() => { handleGoHome(); }}
          className={classes.goHomeLink}
        >
          {'< Volver'}
        </Link>
        <Paper className={classes.root} elevation={5}>
          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            onKeyPress={(e) => skipEnterPress(e)}
          >
            {
              subtotal(cart) ? (
                <Typography align="left" className={classes.titleForm} variant="h5">
                  Proceso de facturación y activación
                </Typography>
              ) : (
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <Typography align="left" className={classes.titleForm} style={{ paddingTop: '8px' }} variant="h5">
                    Proceso de activación de licencia
                  </Typography>
                  <HelpTooltip title="Estos son los datos con los que se activará la licencia" placement="top-start">
                    <IconButton color="secondary">
                      <HelpIcon style={{ fontSize: 18 }} className={classes.helpIconColor} />
                    </IconButton>
                  </HelpTooltip>
                </Grid>
              )
            }
            {
              subtotal(cart)
                ? (
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className={classes.expansionPanelIcon} />}
                      classes={{ root: classes.panelSummary, expanded: classes.expanded }}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      name="pnlBillling"
                    >
                      <Typography className={classes.expansionPanelTitle} variant="h6">Datos de facturación</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.container}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {
                            userInfo && (
                              <FormControlLabel
                                control={(
                                  <SwitchiOS
                                    checked={!check}
                                    onChange={(e) => getUserInfo(e)}
                                    name="checkedUseCustomerBilling"
                                    color="primary"
                                    disabled={!userInfo}
                                  />
                                )}
                                label="Modificar datos de facturación"
                              />
                            )
                          }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Grid container>
                            <Grid item xs={11}>
                              <Controller
                                as={(
                                  <TextField
                                    select
                                    label="Tipo de persona"
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={userInfo && check}
                                    style={{ marginRight: 10, color: 'red' }}
                                    FormHelperTextProps={{ style: { marginRight: 10 } }}
                                    helperText={errors.personTypeBilling ? errors.personTypeBilling.message : ''}
                                    className={classes.typeInput}
                                  >
                                    <MenuItem value="">Seleccione</MenuItem>
                                    {personType.map(
                                      (td) => (
                                        <MenuItem
                                          key={td.value}
                                          value={td.value}
                                        >
                                          {td.label}
                                        </MenuItem>
                                      ),
                                    )}
                                  </TextField>
                                )}
                                name="personTypeBilling"
                                control={control}
                                rules={Rules.required}
                                error={Boolean(errors.personTypeBilling)}
                                inputProps={{ onChange: (e) => setPersonType(e) }}
                                defaultValue=""
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <HelpTooltip title="Corresponde al dato de la casilla No. 24 de tu RUT" placement="top-start">
                                <IconButton color="secondary">
                                  <HelpIcon style={{ fontSize: 18 }} className={classes.helpIconColor} />
                                </IconButton>
                              </HelpTooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Grid container>
                            <Grid item xs={12} md={loadingRues ? 11 : 12} lg={loadingRues ? 11 : 12}>
                              <Controller
                                as={(
                                  <TextField
                                    select
                                    label="Tipo de documento"
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={userInfo && check}
                                    style={{ marginRight: 10, color: 'red' }}
                                    FormHelperTextProps={{ style: { marginRight: 10 } }}
                                    helperText={errors.documentTypeIdBilling ? errors.documentTypeIdBilling.message : ''}
                                    className={classes.typeInput}
                                  >
                                    <MenuItem value="">Seleccione</MenuItem>
                                    {
                                      getDocumentTypes(documentTypes, personTypeBilling , appName).map(  
                                        (td) => (
                                          <MenuItem
                                            key={td.value}
                                            value={td.value}
                                          >
                                            {td.text}
                                          </MenuItem>
                                        ),
                                      )
                                    }
                                  </TextField>
                                )}
                                name="documentTypeIdBilling"
                                control={control}
                                rules={Rules.required}
                                // onChange={(e) => setDocumentValidationOnChange(e)}
                                error={Boolean(errors.documentTypeIdBilling)}
                                defaultValue=""
                              />
                            </Grid>
                            {loadingRues && getLoading()}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          {
                            watchDocTypeBill === 4 // Nit
                              ? (
                                <>
                                  {isXs && (<br />)}
                                  <InputLabel shrink className={classes.infoText}>
                                    Sin digito de verificación
                                  </InputLabel>
                                </>
                              ) : (
                                ''
                              )
                          }
                          <Grid container>
                            <Grid item xs={watchDocTypeBill === 4 ? 9 : 12} sm={watchDocTypeBill === 4 ? 9 : 12}>
                              <Grid container>
                                <Grid item xs={11}>
                                  <Controller
                                    as={(
                                      <TextField
                                        label="Número de documento"
                                        size="small"
                                        variant="outlined"
                                        required
                                        // disabled={loadingRues}
                                        disabled={userInfo && check}
                                        fullWidth
                                        style={{ marginRight: 10, color: 'red' }}
                                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                                        helperText={errors.documentNumberBilling ? errors.documentNumberBilling.message : ''}
                                        className={classes.typeInput}
                                      />
                                    )}
                                    name="documentNumberBilling"
                                    control={control}
                                    rules={validateDocument(personTypeBilling, watchDocTypeBill)}
                                    error={Boolean(errors.documentNumberBilling)}
                                    defaultValue=""
                                    onKeyDown={(e) => handleKeyDown(e, watchDocTypeBill)}
                                    inputProps={{ onBlur: () => getDefaulttNameByTypeAndDoc(personTypeBilling, 'documentTypeIdBilling', 'documentNumberBilling', 'Billing'), maxLength: 15 }}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <HelpTooltip title="Corresponde al dato de la casilla No. 5 de tu RUT" placement="top-end">
                                    <IconButton color="secondary">
                                      <HelpIcon style={{ fontSize: 18 }} className={classes.helpIconColor} />
                                    </IconButton>
                                  </HelpTooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                            {watchDocTypeBill === 4 ? boxCheckDigit('dvBilling') : ''}
                          </Grid>
                        </Grid>
                        {
                          personTypeBilling === 'J'
                            ? (
                              <Grid item xs={12}>
                                <Controller
                                  as={(
                                    <TextField
                                      label="Razón Social"
                                      size="small"
                                      variant="outlined"
                                      required
                                      // disabled={loadingRues}
                                      disabled={userInfo && check}
                                      fullWidth
                                      style={{ marginRight: 10, color: 'red' }}
                                      FormHelperTextProps={{ style: { marginRight: 10 } }}
                                      helperText={errors.legalNameBilling ? errors.legalNameBilling.message : ''}
                                      className={classes.typeInput}
                                    />
                                  )}
                                  name="legalNameBilling"
                                  control={control}
                                  rules={Rules.DenySpecialCharactersLegalName}
                                  error={Boolean(errors.legalNameBilling)}
                                  defaultValue={!typeLoad || typeLoad === 'Billing' ? check ? billingInfo.legalName : '' : ''}
                                  inputProps={{
                                    maxLength: 450,
                                  }}
                                />
                              </Grid>
                            )
                            : (
                              <>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <Controller
                                    as={(
                                      <TextField
                                        label="Nombres"
                                        size="small"
                                        variant="outlined"
                                        required
                                        // disabled={loadingRues}
                                        disabled={userInfo && check}
                                        fullWidth
                                        style={{ marginRight: 10, color: 'red' }}
                                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                                        helperText={errors.firstNameBilling ? errors.firstNameBilling.message : ''}
                                        className={classes.typeInput}
                                      />
                                    )}
                                    name="firstNameBilling"
                                    control={control}
                                    rules={Rules.letrasTildesMin3}
                                    error={Boolean(errors.firstNameBilling)}
                                    defaultValue=""
                                    inputProps={{
                                      maxLength: 100,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <Controller
                                    as={(
                                      <TextField
                                        label="Apellidos"
                                        size="small"
                                        variant="outlined"
                                        required
                                        // disabled={loadingRues}
                                        disabled={userInfo && check}
                                        fullWidth
                                        style={{ marginRight: 10, color: 'red' }}
                                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                                        helperText={errors.lastNameBilling ? errors.lastNameBilling.message : ''}
                                        className={classes.typeInput}
                                      />
                                    )}
                                    name="lastNameBilling"
                                    control={control}
                                    rules={Rules.letrasTildesMin3}
                                    error={Boolean(errors.lastNameBilling)}
                                    defaultValue=""
                                    inputProps={{
                                      maxLength: 100,
                                    }}
                                  />
                                </Grid>
                              </>
                            )
                        }
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Controller
                            as={(
                              <TextField
                                label="Teléfono / Celular"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                disabled={userInfo && check}
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.cellphoneNumberBilling ? errors.cellphoneNumberBilling.message : ''}
                                className={classes.typeInput}
                              />
                            )}
                            name="cellphoneNumberBilling"
                            control={control}
                            rules={Rules.telCel}
                            error={Boolean(errors.cellphoneNumberBilling)}
                            defaultValue=""
                            inputProps={{
                              maxLength: 10,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Controller
                            as={(
                              <TextField
                                label="Correo Electrónico"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                disabled={userInfo && check}
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.emailBilling ? errors.emailBilling.message : ''}
                                className={classes.typeInput}
                              />
                            )}
                            name="emailBilling"
                            control={control}
                            rules={Rules.email}
                            error={Boolean(errors.emailBilling)}
                            defaultValue=""
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h3 className={classes.contentTextPrimary}>Dirección de facturación</h3>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AutocompleteGoogleMaps
                            name="fulladdressBilling"
                            options={optionsAddressBilling}
                            label="Dirección"
                            placeholder="Escriba la dirección"
                            value={addressMapsBilling}
                            setOptions={setOptionsAddressBilling}
                            setValueMaps={setAddressMapsBilling}
                            inputValue={inputAddressBillingValue}
                            setInputValue={setInputAddressBillingValue}
                            setGeoCoordinate={setGeoCodeBilling}
                            type="Billing"
                            // disabled={false}
                            disabled={userInfo && check}
                            showIconLocation
                            control={control}
                            rules={Rules.required}
                            error={errors}
                            className={classes.typeInput}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Controller
                            render={(props) => (
                              <Autocomplete
                                {...props}
                                options={departaments}
                                noOptionsText="No hay registros"
                                size="small"
                                disableClearable
                                required
                                fullWidth
                                disabled={userInfo && check}
                                getOptionLabel={(option) => option.text}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Departamento"
                                    variant="outlined"
                                    required
                                    disabled={userInfo && check}
                                    error={Boolean(errors.departmentIdBilling)}
                                    style={{ marginRight: 10, color: 'red' }}
                                    FormHelperTextProps={{ style: { marginRight: 10 } }}
                                    helperText={errors.departmentIdBilling ? errors.departmentIdBilling.message : ''}
                                    className={classes.typeInput}
                                  />
                                )}
                                value={valueDepartamentBilling}
                                onChange={(_, data) => {
                                  props.onChange(data);
                                  if (data) {
                                    setValueDepartamentBilling(data);
                                    handleChangeStateCustomerBillingId(data.value);
                                  }
                                }}
                              />
                            )}
                            defaultValue=""
                            name="departmentIdBilling"
                            control={control}
                            rules={Rules.required}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Controller
                            render={(props) => (
                              <Autocomplete
                                {...props}
                                options={citiesBilling}
                                noOptionsText="No hay registros"
                                disableClearable
                                size="small"
                                required
                                fullWidth
                                disabled={userInfo && check}
                                getOptionLabel={(option) => option.cityName}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Ciudad"
                                    variant="outlined"
                                    required
                                    disabled={userInfo && check}
                                    error={Boolean(errors.cityIdBilling)}
                                    style={{ marginRight: 10, color: 'red' }}
                                    FormHelperTextProps={{ style: { marginRight: 10 } }}
                                    helperText={errors.cityIdBilling ? errors.cityIdBilling.message : ''}
                                    className={classes.typeInput}
                                  />
                                )}
                                value={valueCityBilling}
                                onChange={(_, data) => {
                                  props.onChange(data);
                                  if (data) {
                                    setValueCityBilling(data);
                                  }
                                }}
                              />
                            )}
                            defaultValue=""
                            name="cityIdBilling"
                            control={control}
                            rules={Rules.required}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            as={(
                              <TextField
                                label="Información adicional"
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={userInfo && check}
                                className={classes.typeInput}
                              />
                            )}
                            name="additionalInfoBilling"
                            control={control}
                            defaultValue=""
                            inputProps={{ maxLength: 100 }}
                          />
                          <Typography
                            style={{ paddingLeft: '5px', fontSize: '12px' }}
                            className={classes.contentTextSecondary}
                          >
                            Ejemplo: Interior, apartamento, conjunto residencial, barrio, etc.
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ) : (' ')
            }
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expansionPanelIcon} />}
                classes={{ root: classes.panelSummary, expanded: classes.expanded }}
                style={!subtotal(cart) ? { display: 'none' } : {}}
                aria-controls="panel2a-content"
                id="panel2a-header"
                name="pnlService"
              >
                <Typography className={classes.expansionPanelTitle} style={{ paddingTop: '8px' }} variant="h6">Datos de activación de licencia</Typography>
                <HelpTooltip title="Estos son los datos con los que se activará la licencia" placement="top-start">
                  <IconButton>
                    <HelpIcon style={{ fontSize: 18 }} className={classes.helpIconColorLicense} />
                  </IconButton>
                </HelpTooltip>
              </AccordionSummary>
              <AccordionDetails className={classes.container}>
                <Grid container spacing={2}>
                  {
                    userInfo && (
                      <Grid item xs={12}>
                        <Typography style={{ paddingTop: '8px', fontSize: 16 }}>{`Si deseas modificar los datos de la licencia contáctanos: ${parameterCombo && parameterCombo.map((x) => x.label)}`}</Typography>
                      </Grid>
                    )
                  }
                  <Grid item xs={12}>
                    {
                      subtotal(cart) ? (
                        <FormControlLabel
                          control={(
                            <SwitchiOS
                              checked={checkService}
                              onChange={(e) => getUserInfo(e)}
                              name="checkedUseCustomerService"
                              color="primary"
                              disabled={userInfo}
                            />
                          )}
                          label={!userInfo ? 'Usar datos de facturación' : 'Usar datos de servicio'}
                          className={classes.controlLabel}
                        />
                      ) : ''
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel />
                    <Grid container>
                      <Grid item xs={11}>
                        <Controller
                          as={(
                            <TextField
                              select
                              label="Tipo de persona"
                              size="small"
                              variant="outlined"
                              required
                              fullWidth
                              disabled={userInfo}
                              style={{ marginRight: 10, color: 'red' }}
                              FormHelperTextProps={{ style: { marginRight: 10 } }}
                              helperText={errors.personTypeService ? errors.personTypeService.message : ''}
                              className={classes.typeInput}
                            >
                              <MenuItem value="">Seleccione</MenuItem>
                              {personType && personType.map(
                                (td) => (
                                  <MenuItem
                                    key={td.value}
                                    value={td.value}
                                  >
                                    {td.label}
                                  </MenuItem>
                                ),
                              )}
                            </TextField>
                          )}
                          name="personTypeService"
                          control={control}
                          rules={Rules.required}
                          error={Boolean(errors.personTypeService)}
                          inputProps={{ onChange: (e) => setPersonType(e) }}
                          defaultValue=""
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <HelpTooltip title="Corresponde al dato de la casilla No. 24 de tu RUT" placement="top-start">
                          <IconButton color="secondary">
                            <HelpIcon style={{ fontSize: 18 }} className={classes.helpIconColor}/>
                          </IconButton>
                        </HelpTooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid container>
                      <Grid item xs={12} md={loadingRues ? 11 : 12} lg={loadingRues ? 11 : 12}>
                        <InputLabel />
                        <Controller
                          as={(
                            <TextField
                              select
                              label="Tipo de documento"
                              size="small"
                              variant="outlined"
                              required
                              fullWidth
                              disabled={userInfo}
                              style={{ marginRight: 10, color: 'red' }}
                              FormHelperTextProps={{ style: { marginRight: 10 } }}
                              helperText={errors.documentTypeIdService ? errors.documentTypeIdService.message : ''}
                              className={classes.typeInput}
                            >
                              <MenuItem value="">Seleccione</MenuItem>
                              {getDocumentTypes(documentTypes, personTypeService , appName).map(
                                (td) => (
                                  <MenuItem
                                    key={td.value}
                                    value={td.value}
                                  >
                                    {td.text}
                                  </MenuItem>
                                ),
                              )}
                            </TextField>
                          )}
                          name="documentTypeIdService"
                          control={control}
                          rules={Rules.required}
                          error={Boolean(errors.documentTypeIdService)}
                          inputProps={{ onChange: (e) => setPersonType(e) }}
                          defaultValue=""
                        />
                      </Grid>
                      {loadingRues && getLoading()}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    {
                      watchDocTypeSer === 4 // Nit
                        ? (
                          <>
                            {isXs && (<br />)}
                            <InputLabel shrink className={classes.infoText}>
                              Sin digito de verificación
                            </InputLabel>
                          </>
                        ) : (
                          ''
                        )
                    }
                    <Grid container>
                      <Grid item xs={watchDocTypeSer === 4 ? 9 : 12} sm={watchDocTypeSer === 4 ? 9 : 12}>
                        <Grid container>
                          <Grid item xs={11}>
                            <Controller
                              as={(
                                <TextField
                                  label="Número de documento"
                                  size="small"
                                  variant="outlined"
                                  required
                                  // disabled={loadingRues}
                                  fullWidth
                                  disabled={userInfo}
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.documentNumberService ? errors.documentNumberService.message : ''}
                                  className={classes.typeInput}
                                />
                              )}
                              name="documentNumberService"
                              control={control}
                              rules={validateDocument(personTypeService, watchDocTypeSer)}
                              error={Boolean(errors.documentNumberService)}
                              defaultValue=""
                              onKeyDown={(e) => handleKeyDown(e, watchDocTypeSer)}
                              inputProps={{ onBlur: () => getDefaulttNameByTypeAndDoc(personTypeService, 'documentTypeIdService', 'documentNumberService', 'Service'), maxLength: 15 }}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <HelpTooltip title="Corresponde al dato de la casilla No. 5 de tu RUT" placement="top-end">
                              <IconButton color="secondary">
                                <HelpIcon style={{ fontSize: 18 }} className={classes.helpIconColor} />
                              </IconButton>
                            </HelpTooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      {watchDocTypeSer === 4 ? boxCheckDigit('dvService') : ''}
                    </Grid>
                  </Grid>
                  {
                    personTypeService === 'J'
                      ? (
                        <Grid item xs={12}>
                          <Controller
                            as={(
                              <TextField
                                label="Razón Social"
                                size="small"
                                variant="outlined"
                                required
                                // disabled={loadingRues}
                                disabled={userInfo}
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.legalNameService ? errors.legalNameService.message : ''}
                                className={classes.typeInput}
                              />
                            )}
                            name="legalNameService"
                            control={control}
                            rules={Rules.DenySpecialCharactersLegalName}
                            error={Boolean(errors.legalNameService)}
                            defaultValue={!typeLoad || typeLoad === 'Service' ? checkService ? Object.keys(serviceInfo).length > 0 ? serviceInfo.legalName : '' :  '' : '' }
                            inputProps={{
                              maxLength: 450,
                            }}
                          />
                        </Grid>
                      )
                      : (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Nombres"
                                  size="small"
                                  variant="outlined"
                                  required
                                  // disabled={loadingRues}
                                  fullWidth
                                  disabled={userInfo}
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.firstNameService ? errors.firstNameService.message : ''}
                                  className={classes.typeInput}
                                />
                              )}
                              name="firstNameService"
                              control={control}
                              rules={Rules.letrasTildesMin3}
                              error={Boolean(errors.firstNameService)}
                              defaultValue=""
                              inputProps={{
                                maxLength: 100,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Apellidos"
                                  size="small"
                                  variant="outlined"
                                  required
                                  // disabled={loadingRues}
                                  fullWidth
                                  disabled={userInfo}
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.lastNameService ? errors.lastNameService.message : ''}
                                  className={classes.typeInput}
                                />
                              )}
                              name="lastNameService"
                              control={control}
                              rules={Rules.letrasTildesMin3}
                              error={Boolean(errors.lastNameService)}
                              defaultValue=""
                              inputProps={{
                                maxLength: 100,
                              }}
                            />
                          </Grid>
                        </>
                      )
                  }
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      as={(
                        <TextField
                          label="Teléfono / Celular"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          disabled={userInfo}
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.cellphoneNumberService ? errors.cellphoneNumberService.message : ''}
                          className={classes.typeInput}
                        />
                      )}
                      name="cellphoneNumberService"
                      control={control}
                      rules={Rules.telCel}
                      error={Boolean(errors.cellphoneNumberService)}
                      defaultValue=""
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      as={(
                        <TextField
                          label="Correo Electrónico"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          disabled={userInfo}
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.emailService ? errors.emailService.message : ''}
                          className={classes.typeInput}
                        />
                      )}
                      name="emailService"
                      control={control}
                      rules={Rules.email}
                      error={Boolean(errors.emailService)}
                      defaultValue=""
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className={classes.contentTextPrimary}>Dirección sucursal principal</h3>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <AutocompleteGoogleMaps
                      name="fulladdressService"
                      options={optionsAddressService}
                      label="Dirección"
                      placeholder="Escriba la dirección"
                      value={addressMapsService}
                      setOptions={setOptionsAddressService}
                      setValueMaps={setAddressMapsService}
                      inputValue={inputAddressServiceValue}
                      setInputValue={setInputAddressServiceValue}
                      setGeoCoordinate={setGeoCodeService}
                      type="Service"
                      // disabled={false}
                      disabled={userInfo}
                      showIconLocation
                      control={control}
                      rules={Rules.required}
                      error={errors}
                      className={classes.typeInput}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Controller
                      render={(props) => (
                        <Autocomplete
                          {...props}
                          options={departaments}
                          noOptionsText="No hay registros"
                          size="small"
                          disableClearable
                          required
                          fullWidth
                          disabled={userInfo}
                          getOptionLabel={(option) => option.text}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Departamento"
                              variant="outlined"
                              required
                              disabled={userInfo}
                              error={Boolean(errors.departmentIdService)}
                              style={{ marginRight: 10, color: 'red' }}
                              FormHelperTextProps={{ style: { marginRight: 10 } }}
                              helperText={errors.departmentIdService ? errors.departmentIdService.message : ''}
                              className={classes.typeInput}
                            />
                          )}
                          value={valueDepartamentService}
                          onChange={(_, data) => {
                            props.onChange(data);
                            if (data) {
                              setValueDepartamentService(data);
                              handleChangeStateCustomerServiceId(data.value);
                            }
                          }}
                        />
                      )}
                      defaultValue=""
                      name="departmentIdService"
                      control={control}
                      rules={Rules.required}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Controller
                      render={(props) => (
                        <Autocomplete
                          {...props}
                          options={citiesService}
                          noOptionsText="No hay registros"
                          disableClearable
                          size="small"
                          required
                          fullWidth
                          disabled={userInfo}
                          getOptionLabel={(option) => option.cityName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Ciudad"
                              variant="outlined"
                              required
                              disabled={userInfo}
                              error={Boolean(errors.cityIdService)}
                              style={{ marginRight: 10, color: 'red' }}
                              FormHelperTextProps={{ style: { marginRight: 10 } }}
                              helperText={errors.cityIdService ? errors.cityIdService.message : ''}
                              className={classes.typeInput}
                            />
                          )}
                          value={valueCityService}
                          onChange={(_, data) => {
                            props.onChange(data);
                            if (data) {
                              setValueCityService(data);
                            }
                          }}
                        />
                      )}
                      defaultValue=""
                      name="cityIdService"
                      control={control}
                      rules={Rules.required}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      as={(
                        <TextField
                          label="Información adicional"
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled={userInfo}
                          className={classes.typeInput}
                        />
                      )}
                      name="additionalInfoService"
                      control={control}
                      defaultValue=""
                      inputProps={{ maxLength: 100 }}
                    />
                    <Typography
                      style={{ paddingLeft: '5px', fontSize: '12px' }}
                      className={classes.contentTextSecondary}
                    >
                      Ejemplo: Interior, apartamento, conjunto residencial, barrio, etc.
                    </Typography>
                  </Grid>
                  {
                    personTypeService === 'J'
                      ? (
                        <>
                          <Grid item xs={12}>
                            <h3 className={classes.contentTextPrimary}>Datos representante legal</h3>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  select
                                  label="Tipo de documento Representante Legal"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  disabled={userInfo}
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.legalDocumentTypeIdService ? errors.legalDocumentTypeIdService.message : ''}
                                  className={classes.typeInput}
                                >
                                  <MenuItem value="">Seleccione</MenuItem>
                                  {documentTypes && documentTypes.filter((doc) => doc.value === 1 || doc.value === 2 || doc.value === 6).map(
                                    (td) => (
                                      <MenuItem
                                        key={td.value}
                                        value={td.value}
                                      >
                                        {td.text}
                                      </MenuItem>
                                    ),
                                  )}
                                </TextField>
                              )}
                              name="legalDocumentTypeIdService"
                              control={control}
                              rules={Rules.required}
                              error={Boolean(errors.legalDocumentTypeIdService)}
                              defaultValue={!typeLoad || typeLoad === 'Service' ? checkService ? Object.keys(serviceInfo).length > 0 ? serviceInfo.legalDocumentTypeId : '' : '' : ''}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Número de documento Representante Legal"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  disabled={userInfo}
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.legalDocumentNumberService ? errors.legalDocumentNumberService.message : ''}
                                  className={classes.typeInput}
                                />
                              )}
                              name="legalDocumentNumberService"
                              control={control}
                              rules={validateDocument('J', watchLegalDocTypeSer)}
                              error={Boolean(errors.legalDocumentNumberService)}
                              defaultValue={!typeLoad || typeLoad === 'Service' ? checkService ? Object.keys(serviceInfo).length > 0 ? serviceInfo.legalDocumentNumber : '' : '' : ''}
                              onKeyDown={(e) => handleKeyDown(e, watchLegalDocTypeSer)}
                              inputProps={{ maxLength: 15 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Nombres Representante Legal"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  disabled={userInfo}
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.legalFirstNameService ? errors.legalFirstNameService.message : ''}
                                  className={classes.typeInput}
                                />
                              )}
                              name="legalFirstNameService"
                              control={control}
                              rules={Rules.letrasTildesMin3}
                              error={Boolean(errors.legalFirstNameService)}
                              defaultValue={!typeLoad || typeLoad === 'Service' ? checkService ? Object.keys(serviceInfo).length > 0 ? serviceInfo.legalFirstName : '' : '' : ''}
                              inputProps={{ maxLength: 100 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Apellidos Representante Legal"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  disabled={userInfo}
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.legalLastNameService ? errors.legalLastNameService.message : ''}
                                  className={classes.typeInput}
                                />
                              )}
                              name="legalLastNameService"
                              control={control}
                              rules={Rules.letrasTildesMin3}
                              error={Boolean(errors.legalLastNameService)}
                              defaultValue={!typeLoad || typeLoad === 'Service' ? checkService ? Object.keys(serviceInfo).length > 0 ? serviceInfo.legalLastName : '' : '' : ''}
                              inputProps={{ maxLength: 100 }}
                            />
                          </Grid>
                        </>
                      )
                      : (
                        <>
                        </>
                      )
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Grid container spacing={2} className={classes.legalTerms}>
              <Grid item xs={1} style={{ marginLeft: '-10px' }}>
                <Controller
                  render={(props) => (
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      onChange={(e) => props.onChange(e.target.checked)}
                      checked={props.value}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      className={classes.checkOptionFilter}
                    />
                  )}
                  name="legalTermsCheck"
                  control={control}
                  rules={Rules.checkTerminosCondiciones}
                  error={Boolean(errors.legalTermsCheck)}
                  defaultValue={false}
                />
              </Grid>
              <Grid item xs={11} style={{ marginLeft: !isSm ? '-60px' : '10px' }}>
                <span style={{ fontSize: '14px' }}>Autorizo el tratamiento de mis datos personales a Gestión de Seguridad Electrónica bajo su <a href={applicationInfo?.urlPersonalDataTreatmentPolicy} className={classes.policyAndTerms} target="_blank">Política de Tratamiento de Datos Personales</a></span>
                {!isSm ? <br /> : ' '}
                <span style={{ fontSize: '14px' }}>y certifico que he leído y acepto los</span>
                &nbsp;
                <a href={applicationInfo?.urlTermsAndConditions} className={classes.policyAndTerms} target="_blank">Términos y Condiciones</a>
                {errors.legalTermsCheck && (
                  <Typography className={classes.formErrors}>
                    {errors.legalTermsCheck.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box className={classes.submit}>
              {
                !isXs && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      onClick={() => {
                        handleCancelClick();
                      }}
                    >
                      Cancelar
                    </Button>
                  </>
                )
              }
              {
                subtotal(cart)
                  ? (
                    <>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={isXs ? classes.btnResponsive : classes.btn}
                        startIcon={<CreditCardIcon />}
                        onClick={() => {
                          setPaymentMethod('EN_LINEA');
                        }}
                      >
                        Pago en Linea
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={isXs ? classes.btnResponsive : classes.btn}
                        startIcon={<MoneyIcon />}
                        onClick={() => {
                          setPaymentMethod('EFECTIVO');
                        }}
                      >
                        Pago en Efectivo
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={isXs ? classes.btnResponsive : classes.btn}
                      onClick={() => {
                        setPaymentMethod('SIN_PAGO');
                      }}
                    >
                      Activar
                    </Button>
                  )
              }
              {
                isXs && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnCancelResponsive}
                      onClick={() => {
                        handleCancelClick();
                      }}
                    >
                      Cancelar
                    </Button>
                  </>
                )
              }
            </Box>
          </form>
        </Paper>
        {/* popup validacion email */}
        {
          validityEmail === false
          && (
            <ValidationCodeModal
              isOpen={openValidationCodeMail}
              email={emailServiceInput}
              validityCode={validityCode}
              appName={appName}
              onClose={handleCloseValidationMail}
            />
          )
        }
        {/* alerta informativa o error */}
        {
          showMessageAlert
          && (
            <AlertConfirm
              isOpen={openAlertInfo}
              tituloModal={titleAlert}
              mensajeModal={messageAlert}
              handleAccept={handleClickOkAlert}
              showClose={showCloseAlert}
              textButtonAcept={textButtonAccept}
              onCancel={showButtonCancel}
              onClose={handleClickCancelAlert}
            >
              {renderCustomAlert()}
            </AlertConfirm>
          )
        }
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  userInfo: props.auth.userInfo,
  loading: props.purchaseLicense.loading,
  loadingGral: props.general.loading,
  getError: props.purchaseLicense.getError,
  billingInfo: props.purchaseLicense.customerBillingInfo,
  serviceInfo: props.purchaseLicense.customerServiceInfo,
  typeLoad: props.purchaseLicense.typeLoad,
  validityEmail: props.purchaseLicense.validityEmail,
  validityCode: props.purchaseLicense.validityCode,
  approvedValidityEmail: props.purchaseLicense.approvedValidityEmail,
  confirmEmail: props.purchaseLicense.confirmEmail,
  validityChangeCustomer: props.purchaseLicense.validityChangeCustomer,
  showMessageAlert: props.purchaseLicense.showMessageAlert,
  referenceUuidPurchaseOrder: props.purchaseLicense.referenceUuidPurchaseOrder,
  documentTypes: props.general.documentTypes,
  statesAndCities: props.general.statesAndCities,
  applicationInfo: props.general.applicationInfo,
  promoCode: props.home.promoCode,
  cart: CleanCart(props.home.cart, props.home.promoCode),
  appName: props.general.appName,
  citiesBillGoogleAssistant: props.google.citiesBillGoogleAssistant,
  citiesServGoogleAssistant: props.google.citiesServGoogleAssistant,
  parameterCombo: props.general.parameterCombo,
});

const mapDispatchToProps = {
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
  savePurchase: savePurchaseAction,
  getCustomerInformation: getCustomerInformationAction,
  setIsCartOpen: setStatusCart,
  checkIsValidEmail: checkIsValidEmailAction,
  getNameByTypeDocAndDoc: getNameByTypeDocAndDocAction,
  getTokenExternalSource: getTokenExternalSourceAction,
  validityChangeCustomerAct: validityChangeCustomerAction,
  setMsgAlert: setMessageAlertAction,
  resetFormRegister: resetFormRegisterAction,
  getParameterByType: getParameterByTypeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPurchaseOrder);
