/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import Box from '@material-ui/core/Box';
import ProgressBar from '../../components/crosscutting/Progress/ProgressBar';
import {
  getSummaryOrderAction,
  uploadPaymentSupportAction,
  resetUploadPaymentSupportAction,
} from '../../actions/summaryOrder.action';
import { getPurchaseOrderParametersAction } from '../../actions/general.action';
import Loading from '../../components/crosscutting/Loading';

import AlertConfirm from '../../components/crosscutting/AlertConfirm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    ...theme.statusLicense.summaryOrderPapper,
    padding: '15px',
    marginTop: '50px',
    paddingBottom: '20px',
    opacity: '0.95',
    borderRadius: '20px',
    minHeight: 500,
  },
  cardContent: {
    padding: 0,
  },
  title: {
    ...theme.miplan.title,
  },
  totalWrapper: {
    float: 'right',
    paddingTop: 20,
  },
  gridContainer: {
    padding: '20px',
  },
  tableCotainer: {
    marginBottom: 20,
    borderRadius: 5,
  },
  table: {
    minWidth: 300,
  },
  tableCell: {
    fontWeight: 900,
  },
  tableHead: {
    ...theme.home.cart.tableHead,
    heigth: 35,
  },
  subtotal: {
    fontSize: '14px',
    textAlign: 'end',
  },
  totalDescuentos: {
    fontSize: '14px',
    textAlign: 'end',
    fontWeight: 600,
  },
  totalRetenciones: {
    fontSize: '14px',
    textAlign: 'end',
    fontWeight: 600,
  },
  totalCompra: {
    fontSize: '16px',
    textAlign: 'end',
    fontWeight: 600,
    ...theme.summaryOrder.totalPurchase,
  },
  descriptionInvoice: {
    fontSize: '13px',
    textAlign: 'end',
  },
  totalText: {
    textAlign: 'end',
    ...theme.home.cart.totalText,
  },
  taxInclude: {
    textAlign: 'end',
  },
  formErrors: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(13),
  },
  btnAddSupport: {
    ...theme.home.cart.btnAddPackages,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    marginLeft: '0px',
    width: '205px',
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  uploadImage: {
    paddingBottom: 10,
    paddingTop: 19,
  },
  item: {
    border: `2px solid ${theme.palette.primary.main}`,
    margin: '0.5em',
    width: '205px',
    height: '40px',
    color: '#fff',
    float: 'left',
  },
  formLabel: {
    color: 'var(--unnamed-color-575756)',
    font: 'normal normal normal 14px/17px Muli',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: 1,
  },
  summaryOrderColor: {
    ...theme.summaryOrder.loadFiles,
    width: '35%',
  },
  summaryOrderFont: {
    ...theme.summaryOrderFont,
  },
}));

const SummaryOrder = (props) => {
  const classes = useStyles();
  const {
    loading, history, summaryOrder, getSummaryOrder, uploadPaymentSupport,
    uploadedSupportFile, resetUploadPaymentSupport, uploadedRutFile, appName, applicationInfo,
    getPurchaseOrderParameters,
  } = props;

  const [fileSupport, setFileSupport] = useState([]);
  const [fileRUT, setFileRUT] = useState([]);
  const [referenceUuid, setReferenceUuid] = useState('');
  const [showAlert, setShowAlert] = useState(undefined);
  const [invalidImage, setInvalidImage] = useState('');
  const [loadingPage, setLoadingPage] = useState(false);
  const [, setClickedB1] = useState('ON');
  const [, setClickedB2] = useState('ON');
  const [startBarProgress, setStartBarProgress] = useState(false);
  const [startBarProgressRUT, setBarProgressRUT] = useState(false);
  const [fileNameSupport, setFileNameSupport] = useState('');
  const [fileNameRUT, setFileNameRUT] = useState('');
  const [loadFull, setLoadFull] = useState(false);

  useEffect(() => {
    const { refererenceUuid } = props.match.params;
    setReferenceUuid(refererenceUuid);
    getPurchaseOrderParameters();
    getSummaryOrder(refererenceUuid, appName);
  }, []);

  useEffect(() => {
    const loadFile = uploadedSupportFile || uploadedRutFile;
    const supportFile = uploadedSupportFile ? true : (summaryOrder?.fileNamePaymentSupport !== '');
    const rut = uploadedRutFile ? true : (summaryOrder?.fileNamePaymentRUT !== '');
    if (loadFile && supportFile && rut) {
      setLoadFull(true);
      setShowAlert(true);
      if (uploadPaymentSupport === true) {
        setFileSupport([]);
        setFileRUT([]);
      }
    }
  }, [uploadedSupportFile, uploadedRutFile]);

  useEffect(() => {
    if (fileSupport.length && !startBarProgress) {
      uploadPaymentSupport(referenceUuid, fileSupport[0]);
    }
  }, [fileSupport, startBarProgress]);

  useEffect(() => {
    if (fileRUT.length && !startBarProgressRUT) {
      uploadPaymentSupport(referenceUuid, fileRUT[0]);
    }
  }, [fileRUT, startBarProgressRUT]);

  const { handleSubmit } = useForm();

  /**
   * Metodo envio de formulario
   */
  const onSubmit = () => {
    uploadPaymentSupport(referenceUuid, fileSupport[0], fileRUT[1]);
  };

  /**
   *
   * @param {*} event parametro event
   * @returns retorna resultado de cargue de archivo
   */
  // eslint-disable-next-line consistent-return
  const onFileUpload = (event) => {
    event.preventDefault();
    setInvalidImage('');
    // Get the file Id
    const { id } = event.target;
    // Create an instance of FileReader API
    // eslint-disable-next-line camelcase
    const file_reader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|pdf)$/)) {
      setInvalidImage('Debe cargar un archivo valido (.jpg, .jpeg, png, pdf).');
      return false;
    }
    if (file.name.length > 200) {
      setInvalidImage('El nombre del archivo seleccionado no es valido.');
      return false;
    }
    if (file.size > 2097152) {
      setInvalidImage('El tamaño del archivo supera el máximo permitido (2MB).');
      return false;
    }
    if (id === 'fileSupport') {
      setStartBarProgress(true);
      setFileNameSupport('');
    }
    if (id === 'fileRUT') {
      setBarProgressRUT(true);
      setFileNameRUT('');
    }
    file_reader.onload = () => {
      // After uploading the file
      // appending the file to our state array
      // set the object keys and values accordingly
      if (id === 'fileSupport') {
        setFileSupport([...fileSupport, {
          file_id: id, file_name: file.name, uploaded_file: file_reader.result, supportType_file: 'PAYMENT_SUPPORT',
        }]);
      }
      if (id === 'fileRUT') {
        setFileRUT([...fileRUT, {
          file_id: id, file_name: file.name, uploaded_file: file_reader.result, supportType_file: 'RUT',
        }]);
      }
    };
    if (id === 'fileSupport') {
      setFileNameSupport(file !== null && file.name !== null ? file.name : '');
      setTimeout(() => {
        if (file && file.name !== '') {
          setStartBarProgress(false);
          setClickedB1('');
        }
      }, 2000);
    }
    if (id === 'fileRUT') {
      setFileNameRUT(file !== null && file.name !== null ? file.name : '');
      setTimeout(() => {
        if (file && file.name !== '') {
          setBarProgressRUT(false);
          setClickedB2('');
        }
      }, 2000);
    }
    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  };
  /**
   * Redirecciona a home de aplicación
   */
  const redirectToHome = () => {
    if (appName === 'colnomina' || appName === 'firmaya' || appName === 'superguarda') {
      setLoadingPage(true);
      if (applicationInfo?.urlLandingPage) {
        window.location.href = applicationInfo?.urlLandingPage;
      }
    } else {
      history.push('/');
    }
  };

  /**
   * Metodo cancel alerta
   */
  const handelCancelAlert = () => {
    setShowAlert(false);
    resetUploadPaymentSupport();
    setFileSupport([]);
    setFileRUT([]);
    if (loadFull) {
      redirectToHome();
    }
  };

  const onClickedButton1 = () => {
    setClickedB1('ON');
  };
  
  const onClickedButton2 = () => {
    setClickedB2('ON');
  };
  /**
   * Metodo ir a home licenciamiento
   */
  const handleGoHome = () => {
    redirectToHome();
  };

  if (loading || loadingPage) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Link
          component="button"
          style={{ fontWeight: 'bold', fontSize: '15', paddingTop: '15px' }}
          color="primary"
          onClick={() => { handleGoHome(); }}
        >
          {'< Volver'}
        </Link>
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" style={{ marginLeft: 20, fontWeight: 'bold' }}>Resumen de compra</Typography>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <TableContainer className={classes.tableCotainer}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Producto</TableCell>
                      <TableCell className={classes.tableCell} align="center">Cantidad</TableCell>
                      <TableCell className={classes.tableCell} align="right">Valor unitario</TableCell>
                      <TableCell className={classes.tableCell} align="right">Descuento</TableCell>
                      <TableCell className={classes.tableCell} align="right">IVA</TableCell>
                      <TableCell className={classes.tableCell} align="right">Subtotal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                    summaryOrder
                      ? (
                        summaryOrder.summaryPackages.map((row) => (
                          <TableRow
                            key={row.packageId}
                          >
                            <TableCell component="th" scope="row">
                              <Typography variant="subtitle2">
                                {row.packageName}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                variant="outlined"
                                size="small"
                                type="number"
                                disabled
                                name="quantity"
                                autoComplete="off"
                                value={row.quantity}
                                inputProps={{
                                  min: '1', max: '999', step: '1', style: { textAlign: 'center', width: '50px' },
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">{`$${row.unitPrice.toLocaleString('en')}`}</TableCell>
                            <TableCell align="right">{`$${row.discount.toLocaleString('en')}`}</TableCell>
                            <TableCell align="right">{`$${row.taxIva.toLocaleString('en')}`}</TableCell>
                            <TableCell align="right">{`$${row.subTotal.toLocaleString('en')}`}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow key={0}>
                          <TableCell scope="row" align="center" colSpan={7}>
                            <Typography style={{ marginBottom: 10, fontWeight: 500 }}>
                              ¡Orden de compra vacía o no existe!
                            </Typography>
                            <AddShoppingCartIcon color="primary" style={{ fontSize: 40 }} />
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container>
                <Grid item xs={12} md={8}>
                  {
                    summaryOrder && (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {
                          !summaryOrder?.fileNamePaymentSupport ? (
                            <Grid>
                              <Box display="flex" className={classes.uploadImage}>
                                <label htmlFor="fileSupport">
                                  <input
                                    onChange={onFileUpload}
                                    id="fileSupport"
                                    accept="jpeg, .jpg, .png, .gif, .pdf"
                                    type="file"
                                    style={{ display: 'none' }}
                                  />
                                  <Button
                                    onClick={onClickedButton1}
                                    component="span"
                                    variant="contained"
                                    color="primary"
                                    endIcon={<AddCircleOutlineTwoToneIcon />}
                                    className={classes.btnAddSupport}
                                  >
                                    Adjuntar Comprobante
                                  </Button>
                                  {invalidImage && (
                                    <Typography className={classes.formErrors}>
                                      {invalidImage}
                                    </Typography>
                                  )}
                                </label>
                                <Box style={{ width: '50%', paddingLeft: 15 }}>
                                  <Typography
                                    className={classes.formLabel}
                                  >
                                    {fileNameSupport}
                                  </Typography>
                                  <Box style={{ width: '35%' }}>
                                    <ProgressBar
                                      startBarProgress={startBarProgress}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          ) : (
                            <>
                              <Paper className={classes.summaryOrderColor}>
                                <Typography>
                                  {summaryOrder?.fileNamePaymentSupport}
                                </Typography>
                              </Paper>
                              <br />
                            </>
                          )
                        }
                        {
                          !summaryOrder?.fileNamePaymentRUT ? (
                            <Grid>
                              <Box display="flex" style={{ paddingTop: 1 }}>
                                <label htmlFor="fileRUT">
                                  <input
                                    onChange={onFileUpload}
                                    id="fileRUT"
                                    accept="jpeg, .jpg, .png, .gif, .pdf"
                                    type="file"
                                    style={{ display: 'none' }}
                                  />
                                  <Button
                                    onClick={onClickedButton2}
                                    component="span"
                                    variant="contained"
                                    color="primary"
                                    endIcon={<AddCircleOutlineTwoToneIcon />}
                                    className={classes.btnAddSupport}
                                  >
                                    Adjuntar RUT
                                  </Button>
                                  {invalidImage && (
                                    <Typography className={classes.formErrors}>
                                      {invalidImage}
                                    </Typography>
                                  )}
                                </label>
                                <Box style={{ width: '50%', paddingLeft: 15 }}>
                                  <Typography
                                    className={classes.formLabel}
                                  >
                                    {fileNameRUT}
                                  </Typography>
                                  <Box style={{ width: '35%' }}>
                                    <ProgressBar
                                      startBarProgressRUT={startBarProgressRUT}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          ) : (
                            <Paper className={classes.summaryOrderColor}>
                              <Typography>
                                {summaryOrder?.fileNamePaymentRUT}
                              </Typography>
                            </Paper>
                          )
                        }
                      </form>
                    )
                  }
                </Grid>
                <Grid item xs={12} md={4} className={classes.totalWrapper}>
                  {
                    summaryOrder && (
                      <>
                        <Grid container>
                          <Grid item xs={8}>
                            <Typography className={classes.subtotal}>
                              <span>Subtotal: </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.subtotal}>
                              <span>{`$${summaryOrder.subTotal.toLocaleString('en')}`}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography className={classes.totalDescuentos}>
                              <span>Total descuentos: </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.subtotal}>
                              <span>{`$${summaryOrder.totalDiscount.toLocaleString('en')}`}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider className={classes.divider} />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography className={classes.subtotal}>
                              <span>Total IVA: </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.subtotal}>
                              <span>{`$${summaryOrder.taxIva.toLocaleString('en')}`}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography className={classes.subtotal}>
                              <span>Retefuente: </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.subtotal}>
                              <span>{`$${summaryOrder.retefuente.toLocaleString('en')}`}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography className={classes.subtotal}>
                              <span>Reteiva: </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.subtotal}>
                              <span>{`$${summaryOrder.reteiva.toLocaleString('en')}`}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography className={classes.subtotal}>
                              <span>Reteica: </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.subtotal}>
                              <span>{`$${summaryOrder.reteica.toLocaleString('en')}`}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography className={classes.totalRetenciones}>
                              <span>Total Retenciones: </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.subtotal}>
                              <span>{`$${summaryOrder.totalTaxDiscount.toLocaleString('en')}`}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider className={classes.divider} />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography className={classes.totalCompra}>
                              <span>Total: </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.totalCompra}>
                              <span style={{ fontSize: 16, fontWeight: 800 }}>{`$${summaryOrder.totalPurchase.toLocaleString('en')}`}</span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* alerta informativa o error */}
        {showAlert
          && (
            <AlertConfirm
              isOpen={showAlert}
              tituloModal={loadFull ? '¡Se ha adjuntado el comprobante!' : '¡Lo sentimos!'}
              mensajeModal={loadFull
                ? 'Debes esperar a que sea validado y aprobado; \n se te notificará al correo electrónico.'
                : 'No se ha podido cargar el comprobante de pago. \n Por favor intente nuevamente.'}
              handleAccept={handelCancelAlert}
              onClose={handelCancelAlert}
              isOnlyMessage
            />
          )}
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  loading: props.summaryOrder.loading,
  summaryOrder: props.summaryOrder.summaryOrder,
  uploadedSupportFile: props.summaryOrder.uploadedSupportFile,
  uploadedRutFile: props.summaryOrder.uploadedRutFile,
  appName: props.general.appName,
  applicationInfo: props.general.applicationInfo,
});

const mapDispatchToProps = {
  getSummaryOrder: getSummaryOrderAction,
  uploadPaymentSupport: uploadPaymentSupportAction,
  resetUploadPaymentSupport: resetUploadPaymentSupportAction,
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryOrder);
