/* eslint-disable no-const-assign */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getStatusOrderAction } from '../../actions/purchaseLicense.action';
import Loading from '../../components/crosscutting/Loading';
import { VALIDATE_PAYMENT_EPAYCO, SECONDS_VALIDATE_PAYMENT } from '../../config/config';
import { capitalizeFirstLetter } from '../../helpers/generalUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 7,
    textAlign: 'center',
    backgroundColor: theme.statusLicense.containerMessage.backgroundColor,
  },
  titleApproved: {
    ...theme.statusLicense.titleStatusLicense,
    fontWeight: 'bold',
    paddingTop: '20px',
  },
  titleStatus: {
    ...theme.statusLicense.titleStatusLicense,
    fontWeight: 'bold',
    paddingTop: '40px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  validationPayment: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 900,
  },
  logoActivated: {
    paddingTop: '40px',
  },
  appoveLicense: {
    fontSize: '18px',
    paddingLeft: '110px',
    paddingRight: '110px',
  },
  detailStatus: {
    fontSize: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  buttonAction: {
    ...theme.statusLicense.buttonAction,
    fontSize: 15,
    color: '#fff',
    marginTop: 40,
    marginBottom: 40,
    minWidth: 120,
  },
}));

const StatusOrder = (props) => {
  const {
    history, loading, loadingCheckPayment, getStatusOrder, statusOrder, appName,
  } = props;

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [messageStatus, setMessageStatus] = useState('');
  const [secondsToRefresh, setSecondsToRefresh] = React.useState(SECONDS_VALIDATE_PAYMENT);
  const [countValidatePayment, setCountValidatePayment] = React.useState(1);
  const [resetTime, setResetTime] = React.useState(false);
  const [refUuid, setRefUuid] = React.useState(null);

  useEffect(() => {
    const { refererenceUuid } = props.match.params;
    setRefUuid(refererenceUuid);
    const validatePayment = VALIDATE_PAYMENT_EPAYCO ? 0 : -1;
    getStatusOrder(refererenceUuid, appName, validatePayment);
  }, []);

  useEffect(() => {
    if (statusOrder) {
      if (statusOrder.result === true) {
        if (statusOrder?.code === 'OK15') {
          setMessageStatus('¡Activación exitosa!');
        } else {
          setMessageStatus('¡Pago exitoso!');
        }
      } else if (statusOrder.result === false
        && statusOrder?.resultData?.code !== 'WR12') {
        setMessageStatus('¡Lo sentimos!');
      } else if (statusOrder.result === false
        && statusOrder?.resultData?.code === 'WR12') {
        if (VALIDATE_PAYMENT_EPAYCO) {
          setResetTime(true);

          if (secondsToRefresh === 0) {
            setSecondsToRefresh(SECONDS_VALIDATE_PAYMENT);
          }
        } else {
          setMessageStatus('¡Licencia pendiente por activar!');
        }
      }
    }
  }, [statusOrder]);

  useEffect(() => {
    // realiza comprobación de pago en epayco
    if (VALIDATE_PAYMENT_EPAYCO) {
      if (secondsToRefresh > 0) {
        setTimeout(() => {
          if (resetTime) {
            setSecondsToRefresh(SECONDS_VALIDATE_PAYMENT);
            setResetTime(false);
          } else {
            setSecondsToRefresh(secondsToRefresh - 1);
          }
        }, 1000);
      } else if (countValidatePayment <= 6 && statusOrder?.resultData?.code === 'WR12') {
        setCountValidatePayment(countValidatePayment + 1);
        getStatusOrder(refUuid, appName, countValidatePayment);
        setSecondsToRefresh(SECONDS_VALIDATE_PAYMENT);
      }
    }
  });

  const getIconImg = (type) => {
    try {
      switch (type.toLowerCase()) {
        case 'approvedpayment':
          return require(`../../assets/img/iconos/${appName}/Pago aprobado.svg`);
        default:
          return require('../../assets/img/iconos/colfactura/Pago aprobado.svg');
      }
    } catch (error) {
      return require('../../assets/img/iconos/colfactura/Pago aprobado.svg');
    }
  };

  const handleClickHome = () => {
    history.push('/');
  };

  const handleClickExternalApp = () => {
    if (statusOrder && statusOrder.result === true) window.location.href = statusOrder.resultData.urlBaseExternalApp;
    else history.push('/');
  };

  const setButtonGoToApp = () => {
    return `Ir a ${capitalizeFirstLetter(appName)}`;
  };

  if (loading) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Paper
          className={classes.root}
          style={{ marginTop: !isLg ? '40%' : '10%' }}
          elevation={5}
        >
          <>
            {
              statusOrder && statusOrder.result === true ? (
                <>
                  <img src={getIconImg('approvedpayment')} alt="Revisa tu email" className={classes.logoActivated} />
                  <h2 className={classes.titleApproved}>{messageStatus}</h2>
                  <Typography
                    className={classes.appoveLicense}
                  >
                    Hemos enviado a tu email las indicaciones para el uso de tu licencia
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleClickExternalApp}
                    className={classes.buttonAction}
                  >
                    {setButtonGoToApp()}
                  </Button>
                </>
              ) : (
                <>
                  <h2 className={classes.titleStatus}>{messageStatus}</h2>
                  <Typography
                    className={classes.detailStatus}
                  >
                    {statusOrder && statusOrder.resultMessage}
                  </Typography>
                  {
                    statusOrder?.resultData?.code === 'WR12' && (
                      VALIDATE_PAYMENT_EPAYCO ? (
                        <div>
                          {
                          loadingCheckPayment ? (<CircularProgress color="secondary" size={35} />)
                            : (<Typography color="primary" variant="h4" className={classes.validationPayment}>{resetTime ? '' : `${secondsToRefresh} Segundos`}</Typography>)
                          }
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={handleClickHome}
                          className={classes.buttonAction}
                        >
                          Aceptar
                        </Button>
                      )
                    )
                  }
                  {
                    statusOrder?.resultData?.code.includes('ER') && (
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleClickHome}
                          startIcon={<HomeIcon />}
                          className={classes.buttonAction}
                        >
                          Volver a la tienda
                        </Button>
                      </div>
                    )
                  }
                </>
              )
            }
          </>
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  loading: props.purchaseLicense.loading,
  loadingCheckPayment: props.purchaseLicense.loadingCheckPayment,
  statusOrder: props.purchaseLicense.statusOrder,
  appName: props.general.appName,
});

const mapDispatchToProps = {
  getStatusOrder: getStatusOrderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusOrder);
