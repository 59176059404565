const aviableDocuments = [
    ["colfactura" , "CC" ,"N"],
    ["colfactura" , "CE" ,"N"],
    ["colfactura" , "NIT" ,"J"],
    ["colnomina" , "CC" ,"N"],
    ["colnomina" , "CE" ,"N"],
    ["colnomina" , "NIT" ,"J"],
    ["firmaya" , "CC" ,"N"],
    ["firmaya" , "CE" ,"N"],
    ["firmaya" , "PA" ,"N"],
    ["firmaya" , "NIT" ,"J"],
    ["superguarda" , "CC" ,"N"],
    ["superguarda" , "CE" ,"N"],
    ["superguarda" , "PA" ,"N"],
    ["superguarda" , "NIT" ,"J"],
  ];

  /**
   * Get list of aviable documents for each aplication, this filter is according to documentsTypes.js aviable in scr/helpers
   * @param documentTypes to filter
   * @param {*} personType personType for data facturaction and personTypeService for data activation license
   * @param appName current aplication
   * @returns avaibles documents for each application and typePerson
   */
  export const getDocumentTypes = (documentTypes, personType , appName) => {
    let result = [];
    const forCurrentApp = aviableDocuments.filter((current) => 
      current[0] === appName && current[2] === personType
    );
    documentTypes.forEach((currentDoc) => {
      forCurrentApp.forEach(currentApp => {
        if(currentDoc.documentTypeCode === currentApp[1]) {
          result.push({
            id: currentDoc.value,
            label: currentDoc.text,
            value: currentDoc.value,
            text: currentDoc.text,
          });
        }
      });
    });
    return result;
  };