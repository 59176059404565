/* eslint-disable import/prefer-default-export */
import { createTheme } from '@material-ui/core/styles';
import logo from '../assets/img/logo/colfactura.png';
import MuliFont from '../assets/font/muli_font/Muli-Light.ttf';

const muli = {
  fontFamily: 'Muli-Light',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Muli-Light'),
    local('Muli-Light'),
    url(${MuliFont}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default createTheme({
  palette: {
    type: 'dark',
    background: {
      paper: '#232323',
      default: '#232323',
    },
    primary: {
      main: '#16B1F3',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2CC63E',
    },
    error: {
      main: '#B7B500',
    },
  },
  typography: {
    fontFamily: 'Muli, Arial',
    fontSize: 13,
  },
  panelSummary: {
    color: 'white',
  },
  resendEmailLink: {
  },
  verifyEmailLink: {
  },
  checkOptionFilter: {
  },
  switchBase: {
    main: '#9e9e9e',
    track: '#fff',
    checked: '#fff',
    selectTrack: '#16B1F3',
  },
  checkOptionHover: {
  },
  logo,
  styleLogo: {
    zIndex: 1,
  },
  colorHeader: '#121212',
  header: {
    usserLogged: {
      color: '#16B1F3',
    },
  },
  home: {
    paquetes: {
    },
    combos: {
    },
    agregados: {
    },
    backgroundSliderCard: {
      color: '#5F5F5F',
    },
    bgColorHead: {
      bases: 'blue,green,yellow,blue,green,yellow,blue,green,yellow,blue,green,yellow',
      combos: 'green01',
      arrayComplementary: '1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4',
      complement: 'yellow',
      complementaryMyplan: 'blue',
    },
    sliderComboText: {
    },
    sliderComplementText: {
      color: '#323232',
    },
    sliderComplementaryButtonColor: {
      color: 'primary',
    },
    sliderComplementaryButtonTextColor: {
      color: 'primary',
    },
    sliderBackGroundColor: {
    },
    sliderArrow: {
    },
    shoppingCart: {
      color: '#FFFFFF',
    },
    cart: {
      title: {
        color: '#F5D13D',
      },
      tableHead: {
        backgroundColor: '#4E4E4E',
      },
      discountText: {
        color: '#30B442',
      },
      infoDescountTextResponsive: {
        color: '#30B442',
      },
      totalText: {
        color: '#F5D13D',
      },
      promoForm: {
        backgroundColor: '#464646',
        color: '#fff',
        borderRadius: 5,
      },
      promoFormNoResponsive: {
        backgroundColor: '#464646',
        color: '#fff',
        borderRadius: 5,
      },
      slider: {
        backgroundColor: '#3D3D3D',
      },
      btnPromotional: {
        backgroundColor: '#7DD1F8',
      },
      btnPromotionalResponsive: {
        backgroundColor: 'transparent',
        color: '#16B1F3',
        border: '1px solid',
      },
      btnCancelResponsive: {
        backgroundColor: 'transparent',
        color: '#21C2F6',
        border: '1px solid #21C2F6',
      },
      tableCell: {
      },
      textPromotional: {
      },
      btnContinue: {
        fontSize: 14,
      },
      btnContinueResponsive: {
      },
      btnAddPackages: {
        backgroundColor: '#232323',
        '&:hover': {
          backgroundColor: '#4C4C4C',
        },
      },
      summaryCart: {
        backgroundColor: '#585858',
      },
      subtotalText: {
        color: 'primary',
      },
      btnGoToCart: {
        backgroundColor: 'primary',
      },
      btnRemoveFromCart: {
        color: 'primary',
      },
      detailPackage: {
        backgroundColor: '#585858',
      },
      iconShoppingCart: {
        fill: '#CCCCCC',
        '& .b, .c': {
          stroke: '#CCCCCC',
        },
      },
      hoverAddProducts: {
        backgroundColor: '#636363',
      },
      titleDetail: {
        color: '#16B1F3',
      },
      seeMore: {
        color: '#2CC63E',
      },
      seeLess: {
        color: '#2CC63E',
      },
      span: {
        color: '#FFFFFF',
      },
      customBadge: {
      },
      helpIconColor: {
      },
      helpIconColorLicense: {
        color: '#FFFFFF',
      },
      backgroundDialogResponsive: {
      },
      titleHeader: {
      },
      deleteIconFromCart: {
      },
      quantityActionCart: {
        color: '#16C2F6',
      },
      typographyCard: {
      },
      moreLessButton: {
        color: '#2CC63E',
      },
    },
  },
  miplan: {
    bgColorCards: {
      backgroundColor: '#FFFFFF1C',
    },
    appBgColorCards: {
      backgroundColor: '#FFFFFF1C',
      boxShadow: 'none',
    },
    rootCard: {
      border: 'none',
      backgroundColor: '#FFFFFF1C',
    },
    title: {
      color: '#FECA28',
    },
    subtitle: {
      color: '#fff',
    },
    cardHeader: {
      color: '#fff',
    },
    featureName: {
    },
    featureValue: {
      color: '#16B1F3',
    },
    buttonCard: {
      color: '#fff',
      backgroundColor: '#636363',
    },
    hoverButtonCard: {
      color: '#fff',
      backgroundColor: '#636363',
    },
    iconCard: {
      color: '#16B1F3',
    },
    btnDirectPlan: {
      backgroundColor: '#16B1F3',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#0f7baa',
      },
    },
    circularColor: {
      color: '#FFFFFF',
    },
    tablePlanTable: {
      color: '#000000',
      backgroundColor: '#2CC63E',
      fontWeight: 700,
    },
    tableCellPlanTable: {
      color: '#FFFFFF',
      backgroundColor: '#FFFFFF1C',
    },
    purchasedPlans: {
      color: '#fff',
    },
    plansTypography: {
      color: '#17DAFA',
    },
    spanPlanTable: {
      color: '#F5D13D',
    },
    reportIcon: {
      color: '#F5D13D',
    },
  },
  registerForm: {
    masterContainer: {
      backgroundColor: '#606060',
    },
    containerForm: {
      backgroundColor: '#4C4C4C',
      expansionPanel: {
        color: '#242121',
      },
    },
    activatePlan: {
      buttonActivate: {
        backgroundColor: '#39DBF6',
        color: '#fff',
      },
      policyAndTerms: {
        color: '#00C246',
      },
    },
    btnCancel: {
    },
    btnResponsive: {
    },
    goHomeLink: {
    },
    titleForm: {
    },
    contentTextSecondary: {
      main: '#2CC63E',
    },
    typeInput: {
      position: 'relative',
      borderRadius: '4px',
    },
    checkDigit: {
      main: '#2CC63E',
    },
    controlLabel: {
    },
  },
  statusLicense: {
    containerMessage: {
      backgroundColor: '#3A3A3A',
    },
    titleStatusLicense: {
      color: '#F4D612',
    },
    summaryOrderPapper: {
      backgroundColor: '#313131',
    },
    buttonAction: {
      backgroundColor: '#16B1F3',
      '&:hover': {
        backgroundColor: '#39DBF6',
      },
    },
  },
  alertPopup: {
    iconClose: {
      color: '#16B1F3',
    },
    titleAlert: {
      color: '#E2C105',
    },
    buttonCancel: {
      backgroundColor: '#16B1F3',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#16B1F3',
      },
    },
    buttonAccept: {
      backgroundColor: '#16B1F3',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#16B1F3',
      },
    },
    secondaryMessageText: {
      color: '#c3c3c3',
    },
  },
  summaryOrder: {
    loadFiles: {
      backgroundColor: '#7D7D7D',
      color: '#F4F4F4',
    },
    totalPurchase: {
      color: '#17AAE8',
    },
  },
  backOffice: {
    configurations: {
      title: {
        color: '#003582',
      },
      editIcon: {
        color: '#009BDD',
      },
    },
  },
  helpToolTip: {
    root: {
      backgroundColor: '#94F39B',
      color: '#121212',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          background: '#D8D8D817',
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        },
        '@font-face': [muli],
      },
    },
  },
});
