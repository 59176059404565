import React from 'react';
import ReactDOM from 'react-dom';

import firmaYaTheme from './theme/FirmaYa';
import gseTheme from './theme/Gse';
import colfacturaTheme from './theme/Colfactura';
import firmaMailTheme from './theme/FirmaMail';
import colnominaTheme from './theme/Colnomina';
import superGuardaTheme from './theme/SuperGuarda';

import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './routes/App';
import {
  API_KEY_GOOGLE_MAPS,
} from './config/config';

const request = new XMLHttpRequest();
request.open('GET', document.location, false);
request.send(null);
const appName = request.getResponseHeader('appName');

const SelectTheme = (app) => {
  let selectedTheme;
  switch (app) {
    case 'firmaya':
      selectedTheme = firmaYaTheme;
      localStorage.setItem('APP_THEME', 'orange');
      break;
    case 'license':
      selectedTheme = gseTheme;
      localStorage.setItem('APP_THEME', 'light');
      break;
    case 'colfactura':
      selectedTheme = colfacturaTheme;
      localStorage.setItem('APP_THEME', 'dark');
      break;
    case 'firmamail':
      selectedTheme = firmaMailTheme;
      localStorage.setItem('APP_THEME', 'light');
      break;
    case 'colnomina':
      selectedTheme = colnominaTheme;
      localStorage.setItem('APP_THEME', 'dark');
      break;
    case 'superguarda':
      selectedTheme = superGuardaTheme;
      localStorage.setItem('APP_THEME', 'light');
      break;
    default:
      selectedTheme = gseTheme;
      localStorage.setItem('APP_THEME', 'light');
      break;
  }
  return selectedTheme;
};

const theme = SelectTheme(appName);

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

if (typeof window !== 'undefined') {
  if (!document.querySelector('#google-maps')) {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${API_KEY_GOOGLE_MAPS}&libraries=places`,
      document.querySelector('head'),
      'google-maps',
    );
  }
}

ReactDOM.render(theme ? <App appName={appName} theme={theme} /> : null, document.getElementById('App'));
