export const LOADING = 'HOME_LOADING';
export const GET_PACKAGES = 'HOME_GET_PACKAGES';
export const UPDATE_CART = 'HOME_UPDATE_CART';
export const STATUS_MODALCART = 'HOME_STATUS_MODALCART';
export const STATUS_MODALCART_EXT = 'HOME_STATUS_MODALCART_EXT';
export const SET_PROMO_CODE = 'HOME_SET_PROMO_CODE';
export const GET_MY_PLANS = 'GET_MY_PLANS';
export const STATUS_MODALPACKAGES = 'STATUS_MODALPACKAGES';
export const GET_PACKAGESBYFEATURE = 'GET_PACKAGESBYFEATURE';
export const SET_LIST_CAR = 'SET_LIST_CAR';
export const GET_PACKAGES_FROM_LOCAL = 'HOME_GET_PACKAGES_FROM_LOCAL';
export const GET_PRICE_BY_LICENSE_TYPE_EMPLOYEE = 'GET_PRICE_BY_LICENSE_TYPE_EMPLOYEE';
export const GET_QUANTITY_LICENSE_EMPLOYEE = 'GET_QUANTITY_LICENSE_EMPLOYEE';
export const GET_PRICE_BY_LICENSE_TYPE_DOCUMENTS = 'GET_PRICE_BY_LICENSE_TYPE_DOCUMENTS';
export const GET_QUANTITY_LICENSE_DOCUMENTS = 'GET_QUANTITY_LICENSE_DOCUMENTS';
export const GET_PRICE_BY_LICENSE_TYPE = 'GET_PRICE_BY_LICENSE_TYPE';
export const GET_QUANTITY_LICENSE = 'GET_QUANTITY_LICENSE';
export const HOME_SHOW_CART = 'HOME_SHOW_CART';
export const CLEAR_ALL_CART = 'HOME_CLEAR_ALL_CART';
export const GET_DETAIL_EMPLOYEE = 'GET_DETAIL_EMPLOYEE';
export const SET_DETAIL_EMPLOYEE = 'SET_DETAIL_EMPLOYEE';