/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import axios from 'axios';
import {
  LICENSE_LOADING, GET_LICENSES_CUSTOMER, GET_DETAIL_LICENSES_CUSTOMER,
  CLEAR_LICENSES_CUSTOMER, SET_CHIPS_FILTERS_LICENSES, CHANGE_CHIPS_FILTERS_LICENSES,
  ADD_LICENSE_CUSTOMER, GET_CUSTOMER_INFORMATION_LICENSE, GET_LICENSE_INFORMATION,
} from '../types/license.type';
import { setMessageAlertAction } from './general.action';
import { API_LICENCIAMIENTO } from '../config/config';
import { defaultHeaders, sortBy } from '../helpers/generalUtils';
import AddedLicenseSuccessfullyIcon from '../assets/img/iconos/license/AddedLicenseSuccessfullyIcon.svg';

// eslint-disable-next-line import/prefer-default-export
export const getLicenseUsageCustomerAction = (skip, take, filter, dataCustomer) => async (dispatch, getState) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const { license: { licenseCustomer } } = getState();
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pSkip: skip,
      pTake: take,
      pFilter: filter,
    });
    const customer = {
      DocumentType: dataCustomer ? dataCustomer.DocumentType : null,
      DocumentNumber: dataCustomer ? dataCustomer.DocumentNumber : null,
      ServiceName: null,
    };
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/license/getLicenseUsage`, customer, config);

    if (response.status === 200) {
      const { records } = response.data.result;
      if (!filter) {
        if (licenseCustomer) Array.prototype.push.apply(licenseCustomer, records.licenseUsageList);
        const arrUpdated = Object.assign([], licenseCustomer.length === 0 ? records.licenseUsageList : licenseCustomer);
        dispatch({
          type: GET_LICENSES_CUSTOMER,
          payload: arrUpdated,
          rownums: records.countRecords,
          nextSkipLicense: records.nextSkipLicense,
        });
      } else {
        dispatch({
          type: GET_LICENSES_CUSTOMER,
          payload: records.licenseUsageList,
          rownums: records.countRecords,
          nextSkipLicense: records.nextSkipLicense,
        });
      }
      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LICENSE_LOADING, payload: false });
    dispatch({
      type: GET_LICENSES_CUSTOMER,
      payload: [],
      rownums: 0,
      nextSkipLicense: 0,
    });
  }
};

export const getClearLicensesCustomerAction = () => async (dispatch) => {
  dispatch({
    type: GET_LICENSES_CUSTOMER,
    payload: [],
    rownums: 0,
    nextSkipLicense: 0,
  });
};

export const getCustomerInformationLicenseAction = (Idcustomer, docType, doc) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    // Object.assign(config.headers, {
    //   customerId: Idcustomer,
    //   DocumentType: docType,
    //   DocumentNumber: doc,
    // });

    const data = {
      CustomerId: Idcustomer,
      DocumentNumber: doc.toString(),
      DocumentType: docType.toString(),
    };
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/Customer/GetCustomer`,
      data, config,
    );
    if (response.status === 200) {
      dispatch({
        type: GET_CUSTOMER_INFORMATION_LICENSE,
        payload: response.data,
      });
    }
  } catch (error) {
    console.error(error);
    const { response } = error;
    if (response.status === 404) {
      dispatch({
        type: GET_CUSTOMER_INFORMATION_LICENSE,
        payload: response.data,
      });
    }
  }
};

export const setFiltersLicenseAction = (type, service, customer, optionsFilter) => async (dispatch, getState) => {
  try {
    const { license: { chipsFiltersOptions } } = getState();
    let chipsOptions = chipsFiltersOptions;
    // dispatch({ type: CHANGE_CHIPS_FILTERS_LICENSES, payload: [] });

    if (type === 'Load') {
      // let filtersAux = [];
      // licenses.map((item) => {
      if (customer) {
        if (chipsOptions.filter((x) => x.key === customer.documentNumber).length === 0) {
          const customerFilter = {
            id: customer.CustomerId,
            key: customer.documentNumber,
            type: 'Customer',
            value: customer.customerName,
            default: true,
          };
          chipsOptions.push(customerFilter);
          chipsOptions = chipsOptions.sort(sortBy('type', false, (a) => a.toUpperCase()));
        }
        service.licensePlan.map((license) => {
          if (chipsOptions.filter((x) => x.key === license.serviceId).length === 0) {
            const serviceFilter = {
              id: license.purchaseOrderLicenseId,
              key: license.serviceId,
              type: 'Service',
              value: license.serviceName,
              default: true,
            };
            chipsOptions.push(serviceFilter);
            chipsOptions = chipsOptions.sort(sortBy('type', false, (a) => a.toUpperCase()));
          }
        });
      }
    } else if (type === 'Customer') {
      if (customer) {
        chipsOptions = chipsOptions.filter((x) => x.type !== 'Customer');
        const CustomerFilter = {
          id: customer.id,
          key: customer.id,
          type: 'Customer',
          value: customer.text,
          default: true,
        };
        chipsOptions.push(CustomerFilter);
      }
      chipsOptions = chipsOptions.sort(sortBy('type', false, (a) => a.toUpperCase()));

      dispatch({ type: CHANGE_CHIPS_FILTERS_LICENSES, payload: chipsOptions });
    } else if (type === 'Service') {
      Object.values(optionsFilter).forEach((item) => {
        if (chipsOptions.filter((x) => x.key === item.id && x.type === 'Service').length === 0) {
          if (item.filtered === true) {
            const serviceFilter = {
              id: item.id,
              key: item.id,
              type: 'Service',
              value: item.label,
              default: false,
            };
            chipsOptions.push(serviceFilter);
          }
        } else if (item.filtered === false) {
          const filter = chipsOptions.find((x) => x.id === item.id);
          if (filter?.default === false) {
            chipsOptions = chipsOptions.filter((x) => x.key !== item.id);
          }
        }
      });

      dispatch({ type: CHANGE_CHIPS_FILTERS_LICENSES, payload: chipsOptions });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getDetailLicensesCustomerAction = (
  dataRequest,
  loadFilters,
  enqueueSnackbar,
) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const config = defaultHeaders();

    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/license/getDetailsLicenseCustomer`, dataRequest, config);
    if (response.status === 200) {
      const { records } = response.data.result;
      dispatch({ type: GET_DETAIL_LICENSES_CUSTOMER, payload: response.data.result.records });

      if (records.length > 0 && loadFilters) {
        records.map((item) => {
          const customer = item.customerInfo;
          item.serviceLicense.map((service) => {
            dispatch(setFiltersLicenseAction('Load', service, customer, null));
          });
        });
      }

      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    const { response } = error;
    console.log('error al consultar licencias de cliente', response);
    dispatch({ type: LICENSE_LOADING, payload: false });
    enqueueSnackbar(response.status === 404
      ? 'No se encontraron licencias para este cliente en el filtro seleccionado'
      : 'La solicitud no ha podido ser procesada', {
      variant: response.status === 404
        ? 'warning'
        : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }
};

export const clearLicensesCustomerAction = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_LICENSES_CUSTOMER, payload: [] });
    dispatch({ type: SET_CHIPS_FILTERS_LICENSES, payload: [] });
  } catch (error) {
    console.error(error);
  }
};

export const addNewLicenseCustomerAction = (
  dataRequest,
  enqueueSnackbar,
) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const config = defaultHeaders();

    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/purchaseOrderLicense/AddNewCustomerLicense`, dataRequest, config);

    if (response.status === 200) {
      const { result } = response.data;
      dispatch({ type: ADD_LICENSE_CUSTOMER, payload: result.records });
      dispatch(
        setMessageAlertAction({
          category: 'createNewLicense',
          typeMsg: 'Success',
          tittleMsg: '¡Perfecto!',
          img: AddedLicenseSuccessfullyIcon,
          message: '¡Licencia añadida con éxito!',
          isOnlyMessage: true,
          autoHideAlert: true,
          showClose: false,
          showCloseAlert: false,
          showButtonCancel: true,
          popupStyle: { padding: '10%' },
          contentStyle: { display: 'grid', justifyItems: 'center' },
          styleMessage: { color: '#E45501', fontSize: 20 },
        }),
      );
      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    dispatch({ type: LICENSE_LOADING, payload: false });
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.result.error.errorDetail, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Se ha presentado un error al añadir la licencia al cliente', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
  }
};

export const saveCustomerAction = (
  formData,
  appName,
  history,
  geoCodeService,
  ImagePopup,
) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const customer = {
      personType: formData.personTypeService,
      documentTypeId: formData.documentTypeIdService,
      documentNumber: formData.documentNumberService,
      legalName: formData.personTypeService === 'J' ? formData.legalNameService : null,
      firstName: formData.firstNameService,
      lastName: formData.lastNameService,
      cellphoneNumber: formData.cellphoneNumberService,
      email: formData.emailService,
      departmentId: formData.departmentIdService.value
        ? formData.departmentIdService.value
        : formData.departmentIdService,
      cityId: formData.cityIdService.id
        ? formData.cityIdService.id
        : formData.cityIdService,
      fulladdress: formData.fulladdressService.description,
      legalDocumentTypeId: formData.legalDocumentTypeIdService,
      legalDocumentNumber: formData.legalDocumentNumberService,
      legalFirstName: formData.legalFirstNameService,
      legalLastName: formData.legalLastNameService,
      dv: formData.dvService,
      addressAdditionalInformation: formData.additionalInfoService,
      addressLatGeocode: geoCodeService ? geoCodeService.lat : null,
      addressLngGeocode: geoCodeService ? geoCodeService.lng : null,
      contractStartDate: formData.contractStartTime ? formData.contractStartTime.format() : null,
      contractEndDate: formData.contractEndTime ? formData.contractEndTime.format() : null,
      linkToApp: formData.linkToApp,
    };

    console.log(JSON.stringify(customer));
    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/Customer/SetCustomer`,
      customer, config,
    );

    const responseOrder = response.data;
    console.log(JSON.stringify(response));

    if (response.status === 200) {
      dispatch({ type: LICENSE_LOADING, payload: false });
      dispatch(
        setMessageAlertAction({
          category: 'license',
          typeMsg: 'Success',
          tittleMsg: '¡Perfecto!',
          img: ImagePopup,
          message: responseOrder.statusMessage,
          isOnlyMessage: true,
          autoHideAlert: true,
          showClose: false,
          showCloseAlert: false,
          showButtonCancel: true,
          popupStyle: { padding: '10%' },
          contentStyle: { display: 'grid', justifyItems: 'center' },
          styleMessage: { color: '#E45501', fontSize: 20 },
        }),
      );
    }
  } catch (error) {
    console.error(`Error al guardar el cliente: ${error.message}`);
    if (error.response) {
      if (error.response.status === 422) {
        dispatch(
          setMessageAlertAction({
            category: 'license',
            typeMsg: 'Error',
            tittleMsg: 'Error',
            message: '¡La empresa NO fue guardada!',
          }),
        );
      } else {
        dispatch(
          setMessageAlertAction({
            category: 'license',
            typeMsg: 'Error',
            tittleMsg: 'Error',
            message: '¡Error del servidor!',
            isOnlyMessage: true,
          }),
        );
      }
    }
  }
};

export const GetLicenseInformationRangesAction = (purchaseOrderLicenseId) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      purchaseOrderLicenseId,
    });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/license/GetLicenseInformation`, config);
    if (response.status === 200) {
      dispatch({
        type: GET_LICENSE_INFORMATION,
        payload: response.data.result.records,
      });
    }
  } catch (error) {
    console.error(error);
    const { response } = error;
    if (response.status === 404) {
      dispatch({
        type: GET_LICENSE_INFORMATION,
        payload: response.data,
      });
    }
  }
};

export const SetLicenseTransactionRangeAction = (PurchaseOrderLicenseId, licenseTransactionRange, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });

    const saveRanges = {
      PurchaseOrderLicenseId,
      licenseTransactionRange,
    };

    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/license/SetLicenseTransactionRange`,
      saveRanges, config,
    );

    if (response.status === 200) {
      dispatch(
        setMessageAlertAction({
          category: 'updateLicense',
          typeMsg: 'Success',
          tittleMsg: '¡Perfecto!',
          img: AddedLicenseSuccessfullyIcon,
          message: '¡Licencia actualizada con éxito!',
          isOnlyMessage: true,
          autoHideAlert: true,
          showClose: false,
          showCloseAlert: false,
          showButtonCancel: true,
          popupStyle: { padding: '10%' },
          contentStyle: { display: 'grid', justifyItems: 'center' },
          styleMessage: { color: '#E45501', fontSize: 20 },
        }),
      );
      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    const { response } = error;
    console.log('error', response);
    dispatch({ type: LICENSE_LOADING, payload: false });
    enqueueSnackbar(response.status === 422
      ? 'No se pudo actualizar la licencia al cliente.'
      : 'Se ha presentado un error al actualizar la licencia al cliente', {
      variant: response.status === 422
        ? 'warning'
        : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }
};
