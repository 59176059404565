import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/HighlightOff';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Carousel from './Carousel';
import SliderCard from './SliderCard';
import {
  setStatusModalPackage,
} from '../../actions/home.action';
import { responsiveComplementaryModal } from '../../helpers/confResponsiveSlider';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    margin: '15px',
  },
  titleModalCart: {
    ...theme.home.cart.title,
    fontWeight: 700,
    fontSize: 22,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    marginBottom: 0,
    paddingTop: 0,
  },
  itemPackage: {
    padding: '30px',
  },
}));

const PackageModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isOpen, onClose, complementary } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
    >
      <DialogTitle>
        <Typography className={classes.titleModalCart}>Paquetes complementarios</Typography>
        <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid item xs={12} sm={12} className={classes.gridItem}>
          <Paper elevation={5} className={`${classes.paper} ${classes.paperAgregados}`}>
            { complementary
             && complementary.length > 0
              ? (
                <>
                  <Carousel
                    rows={2}
                    slidesToShow={2}
                    responsive={responsiveComplementaryModal}
                    infinite={complementary.length > 1}
                    style={{ padding: '15px' }}
                  >
                    {complementary.map((item) => (
                      
                        <Grid key={item.packageId} item xs={12} className={classes.itemPackage}>
                          <SliderCard
                            key={item.packageId}
                            card={item}
                            showList={false}
                            colorBgHeader={theme.home.bgColorHead.complementaryMyplan}
                            textColor={theme.home.sliderComplementText.color}
                            hideAddCart
                          />
                        </Grid>
                      
                    ))}
                  </Carousel>
                </>
              )
              : (
                <h5>No hay informacion</h5>
              )}
          </Paper>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ home }) => ({
  isOpen: home.isPackageOpen,
  complementary: home.packagesMyPlan.complementary,
});

const mapDispatchToProps = {
  onClose: () => setStatusModalPackage(false),
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageModal);
