/* eslint-disable linebreak-style, react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { setGeoCode } from '../../../helpers/googleMapsUtils';

import {
  getCitiesGoogleAssistantAction,
} from '../../../actions/google.action';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    size: 10,
  },
}));

const CustomAutocompleteGoogleMaps = (props) => {
  const {
    options, label, placeholder, value, type, defaultValue,
    setOptions, setValueMaps, setInputValue, setGeoCoordinate,
    inputValue, className, disabled, showIconLocation,
    control, rules, name, error, getCitiesGoogleAssistant,
  } = props;
  const classes = useStyles();
  const autocompleteService = { current: null };

  const fetch = React.useMemo(
    () => throttle((request, callback) => {
      autocompleteService.current.getPlacePredictions(request, callback);
    }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ['geocode'], componentRestrictions: { country: 'co' } }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        if (newOptions.length > 0) {
          // Si existe direccion suministrada por Api Google Places
          // Adiciona busqueda de usuario
          newOptions.push({
            description: inputValue,
            structured_formatting: {
              main_text: inputValue,
              main_text_matched_substrings: [
                {
                  length: 0,
                  offset: 0,
                },
              ],
              secondary_text: '',
            },
          });
          setOptions(newOptions);
          getCitiesGoogleAssistant(newOptions[0], type);
        } else {
          // En caso de no existir agrega direccion adicionada
          newOptions.push({
            description: inputValue,
            structured_formatting: {
              main_text: inputValue,
              main_text_matched_substrings: [
                {
                  length: 0,
                  offset: 0,
                },
              ],
              secondary_text: '',
            },
          });
          setOptions(newOptions);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleKeyDown = (e) => {
    const validsChars = /[a-zA-Z0-9 #\-,.]/gi;
    if (!e.key.match(validsChars)) {
      e.preventDefault();
    }
  };

  const handleKeyPress = (e) => {
    const validsChars = /[a-zA-Z0-9 #\-,.]/gi;
    if (!e.key.match(validsChars)) {
      e.preventDefault();
    }
  };

  const handleOnPaste = (e) => {
    const str = e.clipboardData.getData('Text');
    const validsChars = /[a-zA-Z0-9 #\-,.]/gi;
    const resValidation = str.match(validsChars);
    if (resValidation.length !== str.length) {
      e.preventDefault();
    }
  };

  return (
    <Controller
      render={(props) => (
        <Autocomplete
          {...props}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          noOptionsText="No hay registros"
          autoComplete
          required
          disabled={disabled}
          fullWidth
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {
            props.onChange(newValue);
            setOptions(newValue ? [newValue, ...options] : options);
            setValueMaps(newValue);
            setGeoCode(newValue, setGeoCoordinate);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              size="small"
              required
              variant="outlined"
              inputProps={{ ...params.inputProps, maxLength: 300 }}
              className={className}
              placeholder={placeholder}
              error={Boolean(error[name])}
              style={{ marginRight: 10, color: 'red' }}
              FormHelperTextProps={{ style: { marginRight: 10 } }}
              helperText={error[name] ? error[name].message : null}
              onKeyDown={(e) => handleKeyDown(e)}
              onKeyPress={(e) => handleKeyPress(e)}
              onPaste={(e) => handleOnPaste(e)}
            />
          )}
          renderOption={(option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
              <Grid container alignItems="center">
                {
                    showIconLocation && (
                      <Grid item>
                        <LocationOnIcon className={classes.icon} />
                      </Grid>
                    )
                  }
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      )}
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || ''}
    />
  );
};

const mapStateToProps = ({ google }) => ({
  loading: google.loading,
});

const mapDispatchToProps = {
  getCitiesGoogleAssistant: getCitiesGoogleAssistantAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomAutocompleteGoogleMaps);
