/* eslint-disable consistent-return, react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import { Grid } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import { format } from 'date-fns';
import clsx from 'clsx';
import moment from 'moment';
import shallowEqual from 'shallowequal';
import {
  EditorState, ContentState, convertFromHTML,
} from 'draft-js';
import SwitchiOS from '../../../../components/form/SwitchiOS';
import EditorWysiwyg from '../../../../components/editor/editorWysiwyg';
import CustomInput from '../../../../components/form/CustomInput';
import CustomCheck from '../../../../components/form/CustomCheck';
import CustomSelect from '../../../../components/form/CustomSelect';
import CustomDate from '../../../../components/form/CustomDate';
import { Rules } from '../../../../helpers/RHFRules';
import {
  getPackagesTypeAction, getChannelAction, getCustomerAction,
  saveNewPackageAction, updatePackageAction, getCountPackagesByTypeAction,
} from '../../../../actions/packets.action';
import { getServicesAction } from '../../../../actions/service.action';
import { getParameterByTypeAction, getTokenExternalSourceAction } from '../../../../actions/general.action';
import CustomAutocomplete from '../../../../components/form/Autocomplete/CustomAutocomplete';
import ProgressBar from '../../../../components/crosscutting/Progress/ProgressBar';
import { getUserDataByIdAction } from '../../../../actions/security.action';
import { isValidTokenAdmin } from '../../../../helpers/authUtils';
import coronaIcon from '../../../../assets/img/iconos/ibuhoo/corona.svg';

const validityTypeOptions = [
  { value: 'A', label: 'Anual' },
  { value: 'M', label: 'Mensual' },
  { value: 'D', label: 'Diario' },
];

const relevanceOptions = [
  { value: 'Alta', label: 'Alta' },
  { value: 'Media', label: 'Media' },
  { value: 'Baja', label: 'Baja' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#16B1F3',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#16B1F3',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: 5,
    },
  },
  boxWrapperItems: {
    display: 'flex',
    '& > *': {
      flexBasis: '100%',
    },
    '& : first-child': {
      marginRight: 5,
    },
  },
  dialogActions: {
    justifyContent: 'center',
  },
  titleMain: {
    top: '105px',
    left: '690px',
    width: '161px',
    height: '30px',
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#1D71B8',
    opacity: 1,
  },
  iconSelect: {
    '& .MuiSvgIcon-root': {
      color: '#009BDD',
    },
  },
  paperOfer: {
    background: '#00000008 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    borderRadius: '10px',
    opacity: 1,
    width: '540px',
    paddingLeft: '16px',
    paddingRight: '24px',
    paddingBottom: '5px',
    font: 'normal normal normal 14px/17px Muli',
    letterSpacing: '0px',
    marginBottom: 10,
    marginTop: 10,
  },
  paperActive: {
    width: '126px',
    height: '33px',
    borderRadius: '10px',
    opacity: 1,
  },
  file: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#fff',
    backgroundColor: '#7d7d7d',
    display: 'inline-block',
    cursor: 'pointer',
    paddingRight: '10px',
    width: 'fit-content',
    textAlign: 'center',
  },
  mat_icon: {
    height: '15px',
    width: '15px',
  },
  paperCard: {
    backgroundColor: '#0000000D',
    borderRadius: 10,
    opacity: 1,
    font: 'normal normal bold 11px/16px Muli',
    paddingLeft: '15px',
    paddingTop: '14px',
    paddingBottom: '15px',
  },
  buttonImage: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #E45501',
    borderRadius: '5px',
    opacity: 1,
    width: '156px',
    height: '33px',
    letterSpacing: '0px',
    '&:hover': {
      background: 'white',
    },
    color: '#E45501',
  },
  formErrors: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(13),
  },
  modal: {
    left: '650px',
    width: '620px',
    height: '1003px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 5px 4px #0000004D',
    borderRadius: '10px',
    opacity: 1,
  },
  button: {
    width: '130px',
    height: '40px',
    background: '#1D71B8 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 3px #0000004D',
    borderRadius: '10px',
    opacity: 1,
  },
  formLabel: {
    color: 'var(--unnamed-color-575756)',
    font: 'normal normal normal 14px/17px Muli',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: 1,
  },
  startOfferKeyboardDate: {
    paddingRight: 20,
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
    '& .MuiInputBase-root': {
      background: 'white',
    },
  },
  endOfferKeyBoardDate: {
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
    '& .MuiInputBase-root': {
      background: 'white',
    },
  },
  uploadImage: {
    paddingBottom: 10,
    paddingTop: 19,
  },
  title: {
    textAlign: 'left',
    letterSpacing: 0,
    color: '#1D71B8',
    opacity: 1,
  },
  viewMore: {
    textAlign: 'right',
    font: 'normal normal 600 12px/17px Muli',
    letterSpacing: '0px',
    color: '#009BDD',
    opacity: 1,
    float: 'right',
  },
  totalOfferValue: {
    background: 'white',
  },
}));

const ModalPackage = (props) => {
  const {
    loading, isOpen, onClose, isNew, item, getPackagesType, packageTypeCombo, getServices,
    servicesCombo, getChannel, channelCombo, updatePackage, setPackageSelected,
    customerCombo, getCustomer, getParameterByType, parameterCombo, saveNewPackage,
    dataUserCreate, dataUserModify, getUserDataById, getTokenExternalSource, getCountPackagesByType,
  } = props;

  const {
    handleSubmit, control, errors, watch, setValue, setError, clearErrors, getValues,
  } = useForm();

  const {
    OfferStartTime, OfferExpirationTime, TotalOffer,
    TaxIva, ApplyOffer, PackagePrice,
  } = watch(['value', 'percentage', 'OfferTypeCode', 'OfferStartTime', 'OfferExpirationTime', 'PackageTypeId', 'TotalOffer',
    'enterprise', 'service', 'channel', 'TaxIva', 'PublicVisibility', 'ApplyOffer', 'Order', 'PackagePrice', 'Relevance']);

  const classes = useStyles();
  const [active, setActive] = React.useState(true);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const [invalidImage, setInvalidImage] = useState('');
  const [files, setFiles] = useState([]);
  const createEditorContent = (editorValueParam) => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(editorValueParam)));
  const [editorValue, setEditorValue] = useState(createEditorContent(''));
  const [summaryHtml, setSummaryHtml] = useState('');
  const [openViewMore, setOpenViewMore] = useState(false);
  const [userCreate, setUserCreate] = useState(null);
  const [userModify, setUserModify] = useState(null);
  const [lastPosition, setLastPosition] = useState(0);
  const [fileName, setFileName] = useState('');
  const [summaryCopy, setSummaryCopy] = useState('');
  const [valuesForm, setValuesForm] = useState({});
  const [startBarProgress, setStartBarProgress] = useState(false);
  const [packageCode, setPackageCode] = useState('');
  const [packageName, setPackageName] = useState('');
  const [packageTypeId, setPackageTypeId] = useState('');
  const [Service, setService] = useState([]);
  const [Channel, setChannel] = useState([]);
  const [Enterprise, setEnterprise] = useState([]);
  const [packageDescription, setPackageDescription] = useState('');
  const [packagePrice, setPackagePrice] = useState(0);
  const [taxIva, setTaxIva] = useState('');
  const [priceUnit, setPriceUnit] = useState('');
  const [validityPeriod, setValidityPeriod] = useState(0);
  const [validityTypeId, setValidityTypeId] = useState('');
  const [visibility, setVisibility] = useState('private');
  const [relevance, setRelevance] = useState('');
  const [order, setOrder] = useState(1);
  const [applyOffer, setApplyOffer] = useState(false);
  const [offerTypeCode, setOfferTypeCode] = useState('VLR');
  const [totalOffer, setTotalOffer] = useState(0);
  const [offerStartTime, setOfferStartTime] = useState(moment());
  const [offerExpirationTime, setOfferExpirationTime] = useState(moment());
  const [fileNameError, setFileNameError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const tokenAdmin = localStorage.getItem('tokenAdminIbuho');
    if (tokenAdmin && isValidTokenAdmin(tokenAdmin)) {
      if (item) {
        const userIdCreated = item.createdby !== null ? item.createdby : 0;
        const userIdModified = item.modifiedby !== null ? item.modifiedby : 0;
        getUserDataById(userIdCreated, 'CREATE');
        getUserDataById(userIdModified, 'MODIFY');
      }
    } else {
      getTokenExternalSource();
    }
  }, [item]);

  const updatePackages = (data) => {
    setPackageCode(data.packageCode);
    setPackageName(data.packageName);
    setPackageTypeId(data.packageTypeId);
    setPackageDescription(data.packageDescription);
    setPackagePrice(data.packagePrice);
    setTaxIva(data.taxIva === 0 ? '0' : data.taxIva);
    setPriceUnit(data.priceUnit.toString());
    setValidityPeriod(data.validityPeriod);
    setValidityTypeId(data.validityTypeId);
    setVisibility(data.publicVisibility ? 'public' : 'private');
    setRelevance(data.relevance);
    setOrder(data.order);
    setApplyOffer(data.applyOffer);
    setOfferTypeCode(data.offerTypeCode);
    setTotalOffer(data.totalOffer > 0 ? data.totalOffer : 0);
    if (data.offerStartTime !== null) {
      setOfferStartTime(data.offerStartTime);
    }
    if (data.offerExpirationTime !== null) {
      setOfferExpirationTime(data.offerExpirationTime);
    }
    setEditorValue(createEditorContent(data.summaryHtml != null ? data.summaryHtml : ''));
    setActive(data.idstate === 0);
    setOpenViewMore(false);
    setFiles([]);
    setFileName('');
    setValuesForm(getValues());
    setSummaryCopy(data.summaryHtml);
  };
  useEffect(() => {
    if (item && isOpen) {
      updatePackages(item);
    }
  }, [item]);

  useEffect(() => {
    if (packageTypeId !== undefined && packageTypeId > 0 && relevance !== undefined && relevance !== '') {
      getCountPackagesByType(packageTypeId, relevance).then((result) => {
        if (result > 0) {
          if (order === '' || order > (result + 1) || !active) {
            setOrder(isNew ? result + 1 : result);
          }
          setLastPosition(result);
        }
      });
    }
  }, [packageTypeId, relevance]);

  useEffect(() => {
    if (dataUserCreate) {
      setUserCreate(dataUserCreate);
    } else {
      setUserCreate(null);
    }
  }, [dataUserCreate]);

  useEffect(() => {
    if (dataUserModify) {
      setUserModify(dataUserModify);
    } else {
      setUserModify(null);
    }
  }, [dataUserModify]);

  useEffect(() => {
    getPackagesType();
    getServices();
    getChannel();
    getCustomer();
    getParameterByType('PackageTax');
    const tokenAdmin = localStorage.getItem('tokenAdminIbuho');
    if (tokenAdmin && isValidTokenAdmin(tokenAdmin)) {
      if (item) {
        const userIdCreated = item.createdby !== null ? item.createdby : 0;
        const userIdModified = item.modifiedby !== null ? item.modifiedby : 0;
        getUserDataById(userIdCreated, 'CREATE');
        getUserDataById(userIdModified, 'MODIFY');
      }
    } else {
      getTokenExternalSource();
    }
  }, []);

  useEffect(() => {
    if (item) {
      // populate to list services
      const serviceSelectedArray = [];
      item.packageService.forEach((servicelItem) => {
        // eslint-disable-next-line max-len
        const serviceFound = servicesCombo.find((serviceItemCombo) => serviceItemCombo.value === servicelItem.serviceId);
        serviceSelectedArray.push({
          id: serviceFound.value,
          text: serviceFound.label,
        });
      });
      // populate to list channel
      setService(serviceSelectedArray);
      const channeSelectedArray = [];
      item.packageChannel.forEach((channelItem) => {
        // eslint-disable-next-line max-len
        const channelFound = channelCombo.find((channelItemCombo) => channelItemCombo.id === channelItem.channelId);
        channeSelectedArray.push(channelFound);
      });
      setChannel(channeSelectedArray);
      // populate to list customer package
      const enterpriseSelectedArray = [];
      if (item.packageCustomer) {
        item.packageCustomer.forEach((customerItem) => {
          if (customerItem.customer != null) {
            enterpriseSelectedArray.push({
              id: customerItem.customerId,
              text: (customerItem.customer.legalName !== null || customerItem.customer.legalName !== '') ? `${customerItem.customer.legalName}`
                : (customerItem.customer.legalFirstName === null && customerItem.customer.legalLastName === null) ? `${customerItem.customer.firstName} ${customerItem.customer.lastName}`
                  : `${customerItem.customer.legalFirstName} ${customerItem.customer.legalLastName}`,
            });
          }
        });
        setEnterprise(enterpriseSelectedArray);
      }
    } else {
      setService([]);
      setChannel([]);
      setEnterprise([]);
    }
  }, [item]);

  useEffect(() => {
    const serviceOption = [];
    servicesCombo.forEach((serviceItemCombo) => { //foreach with capital letter
      const serviceItem = {
        id: serviceItemCombo.value,
        text: serviceItemCombo.label,
      };
      serviceOption.push(serviceItem);
    });
    setServiceOptions(serviceOption);
  }, [servicesCombo]);

  const onFileUpload = (event) => {
    event.preventDefault();
    event.persist();
    setInvalidImage('');
    // Get the file Id
    const { id } = event.target;
    // Create an instance of FileReader API
    // eslint-disable-next-line camelcase
    const file_reader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|pdf)$/)) {
      setInvalidImage('Debe cargar un archivo valido (.jpg, .jpeg, png, pdf).');
      return false;
    }
    if (file.size > 2097152) {
      setInvalidImage('El tamaño del archivo supera el máximo permitido (2MB).');
      return false;
    }
    setStartBarProgress(true);
    file_reader.onload = () => {
      // After uploading the file
      // appending the file to our state array
      // set the object keys and values accordingly
      setFiles([...files, { file_id: id, file_name: file.name, uploaded_file: file_reader.result }]);
    };
    setFileName(file !== null && file.name !== null ? file.name : '');
    setTimeout(() => {
      if (file && file.name !== '') {
        setStartBarProgress(false);
      }
    }, 2000);

    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  };

  const validateTotalOffer = (event) => {
    setTotalOffer(event);
    if (offerTypeCode === 'VLR' && Number.parseInt(event, 10) > Number.parseInt(packagePrice, 10)) {
      setError('TotalOffer', { message: 'El valor total de la oferta no puede superar el valor del paquete' });
    } else {
      clearErrors('TotalOffer');
    }
  };

  const validateGeneralFields = () => {
    let isError = false;
    if (Enterprise.length === 0 && visibility === 'private') {
      setError('enterprise', 'Campo obligatorio');
      isError = true;
    }
    if (Service.length === 0) {
      setError('service', 'Campo obligatorio');
      isError = true;
    }
    if (Channel.length === 0) {
      setError('channel', 'Campo obligatorio');
      isError = true;
    }
    if (fileName === '' && ApplyOffer && isNew) {
      setError('image', 'Campo obligatorio');
      setFileNameError(true);
      isError = true;
    }
    if ((TotalOffer || totalOffer) > Number.parseInt(packagePrice, 10)) {
      setError('TotalOffer', { message: 'El valor total de la oferta no puede superar el valor del paquete' });
      isError = true;
    }
    return isError;
  };

  const validateTimeFields = () => {
    const dtOfferStartTime = moment(moment(OfferStartTime).format('YYYY-MM-DD'));
    const dtNow = moment(moment(new Date()).format('YYYY-MM-DD'));
    const dtOfferExpirationTime = moment(moment(OfferExpirationTime).format('YYYY-MM-DD'));

    if (OfferStartTime === null) {
      setError('OfferStartTime', { message: 'Campo obligatorio' });
      return true;
    }
    if (OfferStartTime === null || offerStartTime.toString() === 'Invalid Date') {
      setError('OfferStartTime', { message: 'Fecha no valida' });
      return true;
    }
    if (OfferExpirationTime === null) {
      setError('OfferExpirationTime', { message: 'Campo obligatorio' });
      return true;
    }
    if (OfferExpirationTime.toString() === 'Invalid Date') {
      setError('OfferExpirationTime', { message: 'Fecha no valida' });
      return true;
    }
    if (dtOfferExpirationTime.isBefore(dtNow)) {
      setError('OfferExpirationTime', { message: 'Fecha fin oferta debe ser mayor o igual a la actual' });
      return true;
    }
    if (dtOfferExpirationTime.isBefore(dtOfferStartTime)) {
      setError('OfferExpirationTime', { message: 'Fecha fin oferta debe ser mayor a la fecha inicio oferta' });
      return true;
    }
    return false;
  };

  const oncloseAndCleanForm = () => {
    setPackageSelected(null);
    setPackageCode('');
    setPackageName('');
    setPackageTypeId(0);
    setService([]);
    setChannel([]);
    setEnterprise([]);
    setPackageDescription('');
    setPackagePrice(0);
    setTaxIva('');
    setPriceUnit('');
    setValidityPeriod(0);
    setValidityTypeId('');
    setVisibility('private');
    setRelevance('');
    setOrder(1);
    setApplyOffer(false);
    setOfferTypeCode('VLR');
    setTotalOffer(0);
    setOfferStartTime(moment());
    setOfferExpirationTime(moment());
    setEditorValue(createEditorContent(''));
    setActive(true);
    setOpenViewMore(false);
    setFiles([]);
    setFileName('');
    setFileNameError(false);
    setValuesForm({});
    setSummaryCopy('');
    onClose();
  };
  const onSubmit = async (dataSent) => {
    const data = dataSent;
    const idState = active ? 0 : 1;
    if (summaryHtml === '' || summaryHtml === undefined) {
      setSummaryHtml(summaryCopy);
    }
    if (!(!isNew && shallowEqual(data, valuesForm)
    && item.idstate === idState && fileName === '' && (summaryHtml === '' || summaryHtml === summaryCopy))) {
      if (ApplyOffer) {
        const validateDates = validateTimeFields();
        if (validateDates) {
          return;
        }
      }
      const validate = validateGeneralFields();
      if (errors && validate) {
        return;
      }
      const servicesIdArray = [];
      const serviceSubmit = data.service || Service;
      serviceSubmit.forEach((serviceItem) => {
        servicesIdArray.push(serviceItem.id);
      });
      // eslint-disable-next-line no-param-reassign
      delete data.service;
      const enterpriseIdArray = [];
      const enterpriseSubmit = data.enterprise || Enterprise;
      enterpriseSubmit.forEach((enterpriseItem) => {
        enterpriseIdArray.push(enterpriseItem.id);
      });
      // eslint-disable-next-line no-param-reassign
      delete data.enterprise;
      const channelIdArray = [];
      const channelSubmit = data.channel || Channel;
      channelSubmit.forEach((channelItem) => {
        channelIdArray.push(channelItem.id);
      });
      // eslint-disable-next-line no-param-reassign
      delete data.channel;
      let totalOffers = 0;
      if (data.TotalOffer !== null && ApplyOffer) {
        if (data.TotalOffer.length > 0) {
          totalOffers = Number.parseInt(data.TotalOffer, 10);
        } else if (data.TotalOffer > 0) {
          totalOffers = data.TotalOffer;
        }
      }
      const dataSave = {
        ...data,
        Id: isNew ? 0 : item.id,
        PackageServiceIdList: servicesIdArray,
        PackageChannelIdList: channelIdArray,
        CustomerIdList: enterpriseIdArray,
        SummaryHtml: summaryHtml === '' && item !== null && item.summaryHtml !== null ? item.summaryHtml : summaryHtml,
        Idstate: active ? 0 : 1,
        PriceUnit: parseFloat(data.PriceUnit),
        TaxIva: Number.parseInt(data.TaxIva, 10),
        PackagePrice: parseFloat(data.PackagePrice),
        ValidityPeriod: Number.parseInt(data.ValidityPeriod, 10),
        TotalOffer: totalOffers,
        Image: files.length > 0 ? {
          FileName: files[0].file_name,
          ContentFileBase64: files[0].uploaded_file,
        } : {
          FileName: '',
          ContentFileBase64: '',
        },
        PublicVisibility: visibility === 'public',
        OfferTypeCode: ApplyOffer ? offerTypeCode : null,
      };
      if (isNew) {
        await saveNewPackage(dataSave, enqueueSnackbar);
      } else {
        await updatePackage(dataSave, enqueueSnackbar);
      }
    }
    setPackageSelected(null);
    oncloseAndCleanForm();
    onClose();
  };

  useEffect(() => {
    const packagePriceInt = PackagePrice !== undefined && PackagePrice !== '' ? Number.parseInt(PackagePrice, 10) : 0;
    const TaxIvaInt = TaxIva !== null && TaxIva !== '' ? Number.parseInt(TaxIva, 10) : 0;
    const packageUnitCalculated = ((packagePriceInt * 100) / (100 + TaxIvaInt));
    setValue('PriceUnit', packageUnitCalculated === 0 ? 0 : packageUnitCalculated.toFixed(2));
  }, [TaxIva, PackagePrice]);

  useEffect(() => {
    if (visibility === 'public') {
      setEnterprise([]);
    }
  }, [visibility]);

  useEffect(() => {
    if (!ApplyOffer) {
      setOfferTypeCode('VLR');
      setTotalOffer(0);
      setOfferStartTime(moment());
      setOfferExpirationTime(moment());
      setFiles([]);
    }
  }, [ApplyOffer]);

  const StyledRadio = (propsRadio) => (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.iconRadio, classes.checkedIcon)} />}
      icon={<span className={classes.iconRadio} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...propsRadio}
    />
  );

  const validateNumberField = (event, maxLength, elementName) => {
    const elementValue = event.target.value.replace(/[^0-9 ]/g, '');
    setValue(elementName, elementValue);
    if (elementValue.length >= maxLength) {
      setValue(elementName, parseInt(elementValue.substring(0, maxLength), 10));
    } else if (elementValue.includes('-') || elementValue === '0') {
      setValue(elementName, 1);
    } else {
      setValue(elementName, elementValue);
    }
  };

  const handleChangePosition = (event) => {
    const orderPosition = event.target.value.replace(/[^0-9 ]/g, '');
    if (orderPosition.length >= 10) {
      setOrder(parseInt(orderPosition.substring(0, 10), 10));
    } else if (orderPosition.includes('-') || orderPosition === '0') {
      setOrder(1);
    } else if (orderPosition > (lastPosition + 1) && isNew) {
      setOrder(lastPosition + 1);
    } else if (orderPosition > lastPosition && !isNew) {
      setOrder(lastPosition);
    } else {
      setOrder(orderPosition);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
      >
        <DialogTitle>
          <Typography className={classes.title} variant="h6">{isNew ? 'Crear paquete' : 'Editar paquete'}</Typography>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={oncloseAndCleanForm}>
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form
            noValidate
            autoComplete="off"
          >
            <Grid
              container
              justify="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  label="Código de paquete *"
                  name="PackageCode"
                  defaultValue={packageCode}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  disabled={!isNew}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomSelect
                  label="Tipo de paquete *"
                  name="PackageTypeId"
                  options={packageTypeCombo}
                  defaultValue={packageTypeId}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomInput
                  label="Nombre del paquete *"
                  name="PackageName"
                  defaultValue={packageName}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomAutocomplete
                  name="service"
                  options={serviceOptions}
                  label="Servicio *"
                  registersSelected={Service}
                  setData={(e) => setService(e)}
                  limitTags={2}
                  placeholder="Servicio"
                  control={control}
                  rules={Rules.required}
                  error={Object.keys(errors).length > 0 && Service.length === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomAutocomplete
                  name="channel"
                  options={channelCombo}
                  label="Canal *"
                  setData={(e) => setChannel(e)}
                  registersSelected={Channel}
                  limitTags={2}
                  placeholder="Canal"
                  control={control}
                  rules={Rules.required}
                  error={Object.keys(errors).length > 0 && Channel.length === 0}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomInput
                  label="Descripción *"
                  name="PackageDescription"
                  defaultValue={packageDescription}
                  multiline
                  fullWidth
                  rows={3}
                  control={control}
                  rule={Rules.letrasTildesNumerosEspacioBlancoCaracteresEspeciales}
                  error={errors}
                  inputProps={{
                    maxLength: 250,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <EditorWysiwyg
                  editorValue={editorValue}
                  setEditorValue={setEditorValue}
                  setSummaryHtml={setSummaryHtml}
                  maxLength={250}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Valor paquete *"
                  name="PackagePrice"
                  type="text"
                  defaultValue={packagePrice}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    onChange: (e) => setPackagePrice(e.target.value),
                  }}
                  onValidate={(e) => validateNumberField(e, 18, 'PackagePrice')}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="% Impuesto iva *"
                  name="TaxIva"
                  defaultValue={taxIva}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={parameterCombo}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Valor unitario *"
                  name="PriceUnit"
                  defaultValue={priceUnit}
                  control={control}
                  error={errors}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  label="Vigencia *"
                  name="ValidityPeriod"
                  defaultValue={validityPeriod}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  onValidate={(e) => validateNumberField(e, 10, 'ValidityPeriod')}
                  inputProps={{
                    min: 1,
                    max: 999,
                    onInput: (e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      if (e.target.value > 999) {
                        e.target.value = 999;
                      }
                    },
                  }}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomSelect
                  label="Tipo Vigencia *"
                  name="ValidityTypeId"
                  defaultValue={validityTypeId}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={validityTypeOptions}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper variant="outlined">
                  <Box display="flex" justifyContent="flex-start">
                    <FormControlLabel
                      control={(
                        <RadioGroup row aria-label="visibility" style={{ marginLeft: '5px', fontSize: '1px' }} value={visibility} onChange={(_event, newValue) => setVisibility(newValue)}>
                          <FormControlLabel
                            classes={{ label: classes.formLabel }}
                            value="public"
                            control={<StyledRadio />}
                            FormControlLabelProps={{ style: { fontSize: '1px' } }}
                            label="Pública"
                          />
                          <FormControlLabel
                            classes={{ label: classes.formLabel }}
                            value="private"
                            control={<StyledRadio />}
                            label="Privada"
                          />
                        </RadioGroup>
                    )}
                      classes={{ label: classes.formLabel }}
                      style={{ marginLeft: 5 }}
                      label="Visibilidad:"
                      labelPlacement="start"
                    />
                    <img src={coronaIcon} alt="coronaIcon" />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomAutocomplete
                  name="enterprise"
                  options={customerCombo}
                  label="Empresa"
                  defaultValue={Enterprise}
                  setData={(e) => setEnterprise(e)}
                  registersSelected={Enterprise}
                  limitTags={2}
                  placeholder="Empresa"
                  control={control}
                  rule={Rules.required}
                  error={Object.keys(errors).length > 0 && Enterprise.length === 0 && visibility === 'private'}
                  disabled={visibility === 'public'}
                  element={Enterprise}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomSelect
                  label="Relevancia *"
                  name="Relevance"
                  defaultValue={relevance}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={relevanceOptions}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  label="Posición *"
                  name="Order"
                  defaultValue={order}
                  onValidate={(e) => handleChangePosition(e)}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  type="number"
                  disabled
                />
              </Grid>

              <Paper variant="outlined" className={classes.paperOfer}>

                <Grid item xs={2}>
                  <CustomCheck
                    label="Oferta"
                    name="ApplyOffer"
                    defaultValue={applyOffer}
                    control={control}
                    error={errors}
                    classesFormControlLabel={{ label: classes.formLabel }}
                    size="small"
                  />
                </Grid>

                { ApplyOffer
                && (
                <>
                  <Box display="flex">
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <Paper variant="outlined">
                        <Box display="flex" justifyContent="flex-start">
                          <FormControlLabel
                            control={(
                              <RadioGroup row aria-label="discountType" style={{ marginLeft: '15px' }} value={offerTypeCode} onChange={(_event, newValue) => setOfferTypeCode(newValue)}>
                                <FormControlLabel
                                  value="VLR"
                                  control={<StyledRadio />}
                                  label="Valor"
                                  classes={{ label: classes.formLabel }}
                                />
                                <FormControlLabel
                                  value="PTJ"
                                  control={<StyledRadio />}
                                  label="Porcentaje"
                                  classes={{ label: classes.formLabel }}
                                />
                              </RadioGroup>
                            )}
                            label="Tipo descuento:"
                            labelPlacement="start"
                            name="OfferTypeCode"
                          />
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <CustomInput
                        label={offerTypeCode === 'VLR' ? 'Valor *' : 'Porcentaje *'}
                        name="TotalOffer"
                        defaultValue={totalOffer}
                        control={control}
                        className={{
                          paddingLeft: 20,
                          paddingBottom: 19,
                        }}
                        inputProps={{
                          maxLength: 18,
                          onChange: (e) => validateTotalOffer(e.target.value),
                        }}
                        styleTextField={{ background: 'white' }}
                        rule={offerTypeCode === 'PTJ' ? Rules.percentage : Rules.numerico}
                        error={errors}
                        styleFromHelperText={{
                          style: {
                            margin: '0 0 0 0px',
                            color: '#d61103',
                            background: '#00000008 0% 0% no-repeat padding-box',
                          },
                        }}
                        id={offerTypeCode === 'VLR' ? 'value' : 'percentage'}
                        InputPropsParam={{
                          startAdornment: <InputAdornment position="start">{offerTypeCode === 'VLR' ? '$' : '%'}</InputAdornment>,
                        }}
                      />
                    </Grid>

                  </Box>
                  <Box display="flex" marginTop={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <CustomDate
                        id="OfferStartTime"
                        name="OfferStartTime"
                        defaultValue={offerStartTime}
                        variant="inline"
                        inputVariant="outlined"
                        classNameKeyboardDate={classes.startOfferKeyboardDate}
                        formatInputValue="DD/MM/yyyy"
                        label="Fecha inicio oferta"
                        size="small"
                        color="primary"
                        rule={Rules.required}
                        placeholder="Fecha inicio oferta"
                        control={control}
                        error={errors.startOffer}
                        formatKeyboardDate="dd/MM/yyyy"
                      />
                      {((Object.keys(errors).length > 0 && OfferStartTime === null)
                          || errors?.OfferStartTime)
                          && (
                            <>
                              <div
                                style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}
                              >
                                { OfferStartTime === null ? 'Campo obligatorio' : errors?.OfferStartTime.message }
                              </div>
                            </>
                          )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <CustomDate
                        id="endOffer"
                        name="OfferExpirationTime"
                        defaultValue={offerExpirationTime}
                        variant="inline"
                        inputVariant="outlined"
                        classNameKeyboardDate={classes.endOfferKeyBoardDate}
                        formatInputValue="DD/MM/yyyy"
                        label="Fecha fin oferta"
                        size="small"
                        color="primary"
                        placeholder="Fecha fin oferta"
                        control={control}
                        error={errors.endOffer}
                        formatKeyboardDate={errors.endOffer ? 'yyyy/dd/MM' : 'dd/MM/yyyy'}
                        disablePast
                      />
                      {((Object.keys(errors).length > 0 && OfferExpirationTime === null)
                        || errors?.OfferExpirationTime)
                          && (
                            <>
                              <div
                                style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}
                              >
                                { OfferExpirationTime === null ? 'Campo obligatorio' : errors?.OfferExpirationTime.message }
                              </div>
                            </>
                          )}
                    </Grid>

                  </Box>

                  <Grid>
                    <Box display="flex" className={classes.uploadImage}>
                      <label htmlFor="fileSupport">
                        <input
                          onChange={onFileUpload}
                          id="fileSupport"
                          accept=".jpeg, .jpg, .png, .pdf"
                          type="file"
                          style={{ display: 'none' }}
                        />

                        <Button
                          color="secondary"
                          variant="contained"
                          component="span"
                          className={classes.buttonImage}
                          endIcon={<AddCircle htmlColor="#E45501" className={classes.mat_icon} />}
                        >
                          Vincular imagen
                        </Button>

                        {invalidImage && (
                        <Typography className={classes.formErrors}>
                          {invalidImage}
                        </Typography>
                        )}
                      </label>
                      <Box style={{ width: '100%', paddingLeft: 15 }}>
                        <Typography className={classes.formLabel}>
                          {fileName}
                        </Typography>
                        <Box style={{ width: '70%' }}>
                          <ProgressBar
                            startBarProgress={startBarProgress}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {(Object.keys(errors).length > 0 || fileNameError) && fileName === '' && ApplyOffer && (item === null || item.urlImageOffer === null)
                      && (
                      <>
                        <div style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}>Campo obligatorio</div>
                      </>
                      )}
                  </Grid>
                </>
                )}
              </Paper>

              <Grid item xs={3}>
                <Paper variant="outlined" className={classes.paperActive}>
                  <Box display="flex" justifyContent="flex-start">
                    <FormControlLabel
                      style={{ paddingTop: 7 }}
                      control={(
                        <SwitchiOS
                          checked={active}
                          onChange={(e) => {
                            setActive(e.target.checked);
                            setOrder(isNew ? lastPosition + 1 : lastPosition);
                          }}
                          name="active"
                          color="primary"
                        />
                      )}
                      classes={{ label: classes.formLabel }}
                      label="Activar"
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={9} />

              {!isNew && !openViewMore
                && (
                  <Grid item xs={12}>
                    <Button
                      edge="false"
                      size="small"
                      className={classes.viewMore}
                      color="primary"
                      onClick={() => { setOpenViewMore(true); }}
                    >
                      Ver más
                      <ExpandMoreIcon color="primary" />
                    </Button>
                  </Grid>
                )}
              {!isNew && (
              <Grid item xs={12}>
                <p />
                <Collapse in={openViewMore}>
                  <Paper elevation={1} className={classes.paperCard}>
                    <Typography variant="caption">
                      <strong>Creado por:</strong>
                      {' '}
                      {userCreate ? (userCreate.names) : ''}
                      <br />
                      <strong>Fecha y hora de creación:</strong>
                      {' '}
                      { item != null ? format(new Date(item.createdon), 'dd/MM/yyyy hh:mm:ss aaaa ') : '' }
                      {' '}
                      <br />
                      <strong>Modificado por:</strong>
                      {' '}
                      {userModify ? (userModify.names) : ''}
                      {' '}
                      <br />
                      <strong>Fecha y hora de modificación:</strong>
                      {' '}
                      { item != null
                        ? moment(new Date(item.modifiedon)).isAfter(new Date(item.createdon))
                          ? format(new Date(item.modifiedon), 'dd/MM/yyyy hh:mm:ss aaaa ')
                          : ''
                        : '' }
                    </Typography>
                  </Paper>
                </Collapse>
              </Grid>
              )}
              {openViewMore
              && (
              <Grid item xs={12}>
                <Button
                  edge="false"
                  size="small"
                  className={classes.viewMore}
                  color="primary"
                  onClick={() => { setOpenViewMore(false); }}
                >
                  Ver menos
                  <ExpandLessIcon color="primary" />
                </Button>
              </Grid>
              )}

            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions} disableSpacing>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            {isNew ? 'Crear' : 'Aceptar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  packets, service, general, security,
}) => ({
  loading: packets.loading,
  packageTypeCombo: packets.packageTypeCombo,
  servicesCombo: service.servicesCombo,
  channelCombo: packets.channelCombo,
  customerCombo: packets.customerCombo,
  countPackageByType: packets.countPackageByType,
  parameterCombo: general.parameterCombo,
  dataUserCreate: security.dataUserCreate,
  dataUserModify: security.dataUserModify,
});

const mapDispatchToProps = {
  getPackagesType: getPackagesTypeAction,
  getServices: getServicesAction,
  getChannel: getChannelAction,
  getCustomer: getCustomerAction,
  getParameterByType: getParameterByTypeAction,
  saveNewPackage: saveNewPackageAction,
  updatePackage: updatePackageAction,
  getUserDataById: getUserDataByIdAction,
  getCountPackagesByType: getCountPackagesByTypeAction,
  getTokenExternalSource: getTokenExternalSourceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPackage);
