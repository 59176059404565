export const LOADING = 'PURCHASE_LOADING';
export const SAVE_PURCHASE = 'SAVE_PURCHASE';
export const SEND_TO_PAYMENT = 'SEND_TO_PAYMENT';
export const GET_ERRORS = 'GET_PURCHASE_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_PURCHASE_ERRORS';
export const GET_CUSTOMER_INFORMATION = 'GET_CUSTOMER_INFORMATION';
export const GET_STATUS_PURCHASEORDER = 'GET_STATUS_PURCHASEORDER';
export const VALIDITY_CUSTOMER_EMAIL = 'VALIDITY_CUSTOMER_EMAIL';
export const CLEAR_VALIDITY_CUSTOMER_EMAIL = 'CLEAR_VALIDITY_CUSTOMER_EMAIL';
export const VALIDITY_EMAIL_CODE = 'PURCHASE_VALIDITY_EMAIL_CODE';
export const GET_VALIDATION_CODE_ERROR = 'GET_VALIDATION_CODE_ERROR';
export const CLEAR_VALIDATION_CODE_ERROR = 'CLEAR_VALIDATION_CODE_ERROR';
export const APPROVED_VALIDITY_EMAIL = 'APPROVED_VALIDITY_EMAIL';
export const PURCHASE_APPROVED_EMAIL = 'PURCHASE_APPROVED_EMAIL';
export const VALIDITY_CHANGE_CUSTOMER = 'VALIDITY_CHANGE_CUSTOMER';
export const CLEAR_VALIDITY_CHANGE_CUSTOMER = 'CLEAR_VALIDITY_CHANGE_CUSTOMER';
export const SET_REFERENCEUUID_PURCHASE_ORDER = 'SET_REFERENCEUUID_PURCHASE_ORDER';
export const PURCHASE_SHOW_MESSAGE = 'PURCHASE_SHOW_MESSAGE';
export const LOADING_CHECK_PAYMENT = 'LOADING_CHECK_PAYMENT';