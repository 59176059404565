/* eslint-disable import/prefer-default-export, no-useless-escape */
export const Rules = {
  email: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(?!.*?\.\.)([A-Za-z0-9_\-.+]{3,})+@([A-Za-z0-9_\-.]{3,})+\.([A-Za-z]{2,})$/,
      message: 'Debe ingresar una dirección de correo válida, mínimo 10 caracteres',
    },
  },
  letrasTildes: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z\u00C0-\u00FF ]*$/,
      message: 'Únicamente es permitido letras y tildes',
    },
  },
  numerico: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]*$/,
      message: 'El campo debe ser numérico.',
    },
  },
  percentage: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([0-9]|[1-9][0-9]|100)$/,
      message: 'El campo debe ser numérico y menor o igual a 100.',
    },
  },
  letrasNumeros: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z0-9]*$/,
      message: 'El campo debe ser alfanumérico.',
    },
  },
  checkTerminosCondiciones: {
    required:
      'Debes aceptar la Política de Tratamiento de Datos Personales y Términos y Condiciones.',
  },
  required: {
    required: 'Campo obligatorio',
  },
  requiredWhitoutMessage: {
    required: '',
  },
  inputsAddress: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([0-9]){0,3}$/,
      message: 'El campo debe contener maximo 3 numeros',
    },
  },
  pasaporte: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([a-zA-Z0-9]){5,15}$/,
      message: 'El campo debe ser alfanumérico, mínimo 5 caracteres.',
    },
  },
  tipoDocumentoNIT: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]{9,9}$/,
      message: 'El campo Nit debe ser numérico, máximo 9 caracteres.',
    },
  },
  otrosDocumentos: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]{5,15}$/,
      message: 'El campo debe ser numérico, mínimo 5 caracteres.',
    },
  },
  letrasTildesMin3: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Zá-úÁ-ÚüÜ]{2}(\s?[a-zA-Zá-úÁ-ÚüÜ])+(?:\s{2,}.{1,97})?\s?$/,
      message: 'Únicamente es permitido letras y tildes, mínimo 3 caracteres.',
    },
  },
  telCel: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]{7,10}$/,
      message: 'El campo debe ser numérico, mínimo 7 caracteres.',
    },
  },
  letrasTildesNumMin3: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z\u00C0-\u00FF ]{3,200}$/,
      message: 'Únicamente es permitido letras y tildes, mínimo 3 caracteres.',
    },
  },
  DenySpecialCharactersLegalName: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(?![, "']).{3}(?:[^', "]| (?!$))[^'\\,"]{0,446}$/,
      message: 'La razón social contiene caracteres inválidos, mínimo 4 caracteres.',
    },
  },
  codigoValidacion: {
    required: 'Ingresa el código de validación',
    pattern: {
      value: /^[0-9]{6,6}$/,
      message: 'Ingresa un código numérico de 6 caracteres.',
    },
  },
  letrasTildesNumeros: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #-]{3,300}$/,
      message: 'Únicamente es permitido letras, tildes y números, mínimo 3 caracteres',
    },
  },
  letrasTildesNumerosEspacioBlancoCaracteresEspeciales: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #\-\s@$!%*?;#=.,;:_,/]*$/,
      message: 'Únicamente es permitido letras, tildes, números y algunos caracteres especiales',
    },
  },
  letrasTildesNumerosDireccion: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #-]{5,300}$/,
      message: 'Únicamente es permitido letras, tildes y números, mínimo 5 caracteres',
    },
  },
};
