import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import AddNewLicense from './index';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  titleDialog: {
    color: '#1D71B8',
    fontSize: 18,
  },
}));

const ModalAddNewLicense = (props) => {
  const {
    onCloseModal, open, licenseSelected,
  } = props;

  const classes = useStyles();

  useEffect(() => {
  }, []);

  function closeModal() {
    onCloseModal(true);
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        disableEnforceFocus
      >
        <DialogTitle>
          <Typography className={classes.titleDialog} color="textSecondary" gutterBottom>
            Editar Licencia
          </Typography>
          {
            closeModal && (
              <IconButton className={classes.closeButton} onClick={closeModal}>
                <CloseIcon color="primary" />
              </IconButton>
            )
          }
        </DialogTitle>
        <DialogContent>
          <AddNewLicense
            licenseSelected={licenseSelected}
            isEditing
            onCloseModal={onCloseModal}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions} disableSpacing />
      </Dialog>
    </>
  );
};

const mapStateToProps = (props) => ({
  appName: props.general.appName,
  showMessageAlert: props.general.showMessageAlert,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddNewLicense);
